import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ca2eb54"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!

  return (_openBlock(), _createBlock(_component_q_avatar, { class: "avatar" }, {
    default: _withCtx(() => [
      _createElementVNode("img", { src: $setup.imageSrc }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}