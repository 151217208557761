<template>
	<q-carousel
		v-model="slide"
		swipeable
		animated
		v-if="facilityPhotos.length"
		navigation-position="bottom"
		height="250px"
		navigation
	>
		<template v-slot:navigation-icon="{ onClick }">
			<base-icon
				name="circle-small"
				color="white"
				class="q-mr-sm"
				bold
				@click="onClick"
			></base-icon>
		</template>
		<q-carousel-slide
			v-for="(photo, index) in facilityPhotos"
			:key="index"
			:name="index"
			class="q-pa-none booking-card__slide"
		>
			<img :src="photo.formattedBase64" alt="Photo" />
		</q-carousel-slide>
	</q-carousel>
	<div class="facility-empty-image-placeholder" v-else>
		<EmptyImage
			:svgWidth="'75px'"
			:svgHeight="'75px'"
			:svgColor="'#c0c0c0'"
			:minHeight="'250px'"
		/>
	</div>
</template>
<script>
import EmptyImage from '@/components/EmptyImage.vue';
import FacilityPhotoData from '@/models/sharedFacilities/FacilityPhotoData';
import { ref } from 'vue';

export default {
	props: {
		facilityPhotos: {
			type: Array(FacilityPhotoData),
			default: null,
		},
	},
	components: {
		EmptyImage,
	},
	setup() {
		const slide = ref(0);

		return {
			slide,
		};
	},
};
</script>
<style lang="scss" scoped>
.booking-card__slide {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.facility-empty-image-placeholder {
	background-color: $ef;
}
</style>
