/* eslint-disable */

export default class AuthorizationCollection {
    private corporationRoles: { [index: number]: number[] } = {};
    private projectRoles: { [index: number]: number[] } = {};
    private buildingRoles: { [index: number]: number[] } = {};
    private propertyRoles: { [index: number]: number[] } = {};

    public constructor() {}

    public addCorporationRole(corporationId: number, roleId: number) {
        if (!this.corporationRoles.hasOwnProperty(corporationId)) {
            this.corporationRoles[corporationId] = [];
            return;
        } 

        if (this.corporationRoles[corporationId].includes(roleId)) {
            return;
        }

        this.corporationRoles[corporationId].push(roleId);
    }

    public addProjectRole(projectId: number, roleId: number) {
        if (!this.projectRoles.hasOwnProperty(projectId)) {
            this.projectRoles[projectId] = [];
        } 

        if (this.projectRoles[projectId].includes(roleId)) {
            return;
        }

        this.projectRoles[projectId].push(roleId);
    }  

    public addBuildingRole(buildingId: number, roleId: number) {
        if (!this.buildingRoles.hasOwnProperty(buildingId)) {
            this.buildingRoles[buildingId] = [];
        } 

        if (this.buildingRoles[buildingId].includes(roleId)) {
            return;
        }

        this.buildingRoles[buildingId].push(roleId);        
    }

    public addPropertyRole(propertyId: number, roleId: number) {
        if (!this.propertyRoles.hasOwnProperty(propertyId)) {
            this.propertyRoles[propertyId] = [];
        } 

        if (this.propertyRoles[propertyId].includes(roleId)) {
            return;
        }

        this.propertyRoles[propertyId].push(roleId);                
    }
}
