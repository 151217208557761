export default class FacilityBlockConfigData {
	public static fromResponseData(responseData: any) {
		return new FacilityBlockConfigData(
			responseData.id,
			responseData.facility_id,
			responseData.duration,
			responseData.min_bookable_blocks,
			responseData.max_bookable_blocks,
			responseData.max_bookings_per_block,
			responseData.price,
		);
	}

    public id: number;
    public facilityId: number;
    public duration: number;
    public minBookableBlocks: number;
    public maxBookableBlocks: number;
    public maxBookingsPerBlock: number;
    public price: number;

    constructor(
    	id: number,
    	facilityId: number,
    	duration: number,
    	minBookableBlocks: number,
    	maxBookableBlocks: number,
    	maxBookingsPerBlock: number,
    	price: number,
    ) {
    	this.id                = id;
    	this.facilityId        = facilityId;
    	this.duration          = duration;
    	this.minBookableBlocks = minBookableBlocks;
    	this.maxBookableBlocks = maxBookableBlocks;
    	this.maxBookingsPerBlock = maxBookingsPerBlock;
    	this.price = price;
    }
}
