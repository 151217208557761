

export default {
	props: {
		logoSpinner: {
			type: Boolean,
			default: false,
		},
		withBox: {
			type: Boolean,
			default: false,
		},
	}
}
