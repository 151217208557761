import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05c14e34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "booking-item-image__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "booking-item-image",
    style: _normalizeStyle('background:' + $props.background)
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($setup.getLocalShortDate($props.date)), 1)
  ], 4))
}