
import useProfileImage from '@/composables/useUserImage';

export default {
	props: {
		userId: {
			type: Number,
			required: true
		}
	},
    
	setup(props) {
		const { imageSrc } = useProfileImage(props.userId)

		return {
			imageSrc
		}
	} 
}
