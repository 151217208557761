import endpoints from '@/api/endpoints';
import axios from 'axios';
import { onMounted, computed, ComputedRef, ref, Ref, watch } from 'vue'
import { useStore } from 'vuex';
import Actions from '@/store/actions';
import { isEqual } from 'lodash';


export default function useNotificationLike(notificationId: number, likesRecipients: Ref<number[]>) {

	const store = useStore();

	const userId: ComputedRef<number> = computed(() => {
		return store.getters['User/getUserId'];
	})

	const likesRecipientsCopy: Ref<Set<number>> = ref(new Set());

	onMounted(() => {
		likesRecipientsCopy.value = new Set(likesRecipients.value);
	});

	const hasLike: ComputedRef<boolean> = computed(() => {
		return likesRecipientsCopy.value.has(userId.value);
	});

	const likeCount: ComputedRef<number> = computed(() => {
		return likesRecipientsCopy.value.size;
	});

	function toggle() {
		const like = !likesRecipientsCopy.value.has(userId.value)

		const data = { likeable: 'Notification', id: notificationId}

		if(like){
			axios.post(endpoints.Notifications.USER_ADD_LIKE, data)
				.then(() => {
					store.dispatch(Actions.SET_NOTIFICATION_USER_LIKE, {value: true, notificationId, userId: userId.value})
				})
		} else {
			axios.delete(endpoints.Notifications.USER_ADD_LIKE, {data})
				.then(() => {
					store.dispatch(Actions.SET_NOTIFICATION_USER_LIKE, {value: false, notificationId, userId: userId.value})
				})
		}
	}

	watch(() => likesRecipients, (updatedArray: Ref<number[]>) => {
		if(!isEqual(updatedArray.value, likesRecipientsCopy.value)) {
			likesRecipientsCopy.value = new Set(updatedArray.value);
		}
	}, { deep: true })

	return {
		hasLike,
		likeCount,
		toggle,
	}
}