
import { computed, ComputedRef, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import NotificationHeader from '../NotificationHeader.vue';
import NotificationImage from '../NotificationImage.vue';
import NotificationInfo from '../NotificationInfo.vue';
import Actions from '@/store/actions';
import Mutations from '@/store/mutations';
import DashboardNotification from '@/models/notifications/DashboardNotification';
import Separator from '@/components/common/Separator.vue';

export default {
    components: {
        NotificationHeader,
        NotificationImage,
        NotificationInfo,
        Separator,
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const notification: ComputedRef<DashboardNotification> = computed(() => store.getters['TenantNotifications/getActiveNotification']);

        onMounted(() => {
            const notificationId = router.currentRoute.value?.query?.notificationId;
            if(notificationId) {
                store.commit(Mutations.RESET_ACTIVE_NOTIFICATION);
			    store.dispatch(Actions.FETCH_TENANT_NOTIFICATION, notificationId);
            }
		})

        return {
            notification
        }
    }
}
