
import Subtitle1 from '@/components/typography/Subtitle1.vue';

export default {
	components: {
		Subtitle1,
	},
	props: {
		hasActiveState: {
			type: Boolean,
			default: true,
		},
		icon: {
			type: String,
		},
	}
};
