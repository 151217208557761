import moment from 'moment';

export default class BookingData {
    public id: number;
    public facilityThumbnail: string;
    public facilityName: string;
    public facilityId: number;
    public userId: number;
    public user: any;
    public startTime: string;
    public endTime: string;
    public createdAt: string;
    public updatedAt: string;
    public deletedAt: string;

    constructor(
    	id: number,
    	facilityThumbnail: string,
    	facilityName: string,
    	facilityId: number,
    	userId: number,
    	user: any,
    	startTime: string,
    	endTime: string,
    	createdAt: string,
    	updatedAt: string,
    	deletedAt: string,
    ) {
    	this.id                = id;
    	this.facilityThumbnail = facilityThumbnail;
    	this.facilityName      = facilityName;
    	this.facilityId        = facilityId;
    	this.userId            = userId;
    	this.user            = user;
    	this.startTime         = startTime;
    	this.endTime           = endTime;
    	this.createdAt         = createdAt;
    	this.updatedAt         = updatedAt;
    	this.deletedAt         = deletedAt;
    }

    get isActiveBooking(): boolean {
    	const today          = moment();
    	const bookingEndDate = moment(this.endTime);

    	return today.isSameOrBefore(bookingEndDate);
    }

    get startTimeTimestamp(): number {
    	return moment(this.startTime).unix();
    }

    get bookingTimes(): string {
    	const startTime = moment.utc(this.startTime).format('hh:mm A');
    	const endTime   = moment.utc(this.endTime).format('hh:mm A');

    	return `${startTime} - ${endTime}`;
    }

    get bookingCheckInTime(): string {
    	return moment.utc(this.startTime).format('hh:mm A');
    }

    get bookingCheckOutTime(): string {
    	return moment.utc(this.endTime).format('hh:mm A');
    }

    get bookingDate(): string {
    	return moment(this.endTime).format('DD-MM-YY');
    }

    get bookingMonthDay(): string {
    	return moment(this.endTime).format('Do');
    }

    get bookingMonth(): string {
    	return `${moment(this.endTime).format('MMM')}.`;
    }

    get background(): string {
    	return `linear-gradient(0deg, rgba(0, 0, 0, 0.39), rgba(0, 0, 0, 0.39)), url(${this.facilityThumbnail}) center center`;
    }
}
