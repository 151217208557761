

import { onMounted, computed, ComputedRef, ref } from 'vue'
import { useStore } from 'vuex';
import Actions from '@/store/actions';
import WithRightSideBar from '@/layout/pageLayouts/WithRightSideBar.vue';
import DashboardNotification from '@/models/notifications/DashboardNotification';
import DashboardFeed from './components/DashboardFeed.vue';
import BookingsSideBar from '../Bookings/components/BookingsSideBar.vue';

export default {
	components: {
		WithRightSideBar,
		DashboardFeed,
		BookingsSideBar,
	},
	emits: ['onLoadMore'],
	setup() {
		const store = useStore();
		const dashboardScrollPosition = ref(0);

		const notifications: ComputedRef<DashboardNotification[]> = computed(() => {
			return store.getters['TenantNotifications/getDashboardNotifications'];
		})

		const isLastPage: ComputedRef<boolean> = computed(() => {
			return store.getters['TenantNotifications/isLastDashboardPage']
		})

		const page: ComputedRef<number> = computed(() => {
			return store.getters['TenantNotifications/getDashboardPage']
		})

		const isLoading: ComputedRef<number> = computed(() => {
			return store.getters['TenantNotifications/getIsLoadingNotifications']
		})

		onMounted(() => {
			store.dispatch(Actions.FETCH_DASHBOARD_NOTIFICATIONS, {
				page: 1,
				type: ['event', 'information'],
				hardReload: true,
			});
		})


		function onLoadMoreNotifications() {
			if(page.value !== 1 && !isLoading.value && !isLastPage.value) {
				store.dispatch(Actions.FETCH_DASHBOARD_NOTIFICATIONS, {
					page: page.value,
					type: ['event', 'information'],
				});
			}
		}

		return {
			notifications,
			isLastPage,
			onLoadMoreNotifications,
			dashboardScrollPosition,
		}
	}
}
