import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-803ee0ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "like-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn, {
      flat: "",
      round: "",
      size: "md",
      onClick: $setup.onToggle
    }, {
      default: _withCtx(() => [
        ($props.hasLike)
          ? (_openBlock(), _createBlock(_component_base_icon, {
              key: 0,
              size: "lg",
              bold: "",
              name: "heart"
            }))
          : (_openBlock(), _createBlock(_component_base_icon, {
              key: 1,
              size: "lg",
              name: "heart"
            }))
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_subtitle_1, {
      label: "",
      class: "like-counter"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.likeCount), 1)
      ]),
      _: 1
    })
  ]))
}