import '@/assets/css/hococo.scss';
import '@/configuration/VeeValidate';
import 'quasar/dist/quasar.css';
import '@/assets/fontawesome/js/all.js';
import '@quasar/extras/material-icons/material-icons.css';

import { createApp } from 'vue';
import vuexI18n from 'vuex-i18n';
import { Quasar, Notify } from 'quasar';
import { components } from '@/plugins/quasar';
import axios from 'axios';

import Actions from '@/store/actions';
import Mutations from '@/store/mutations';
import App from './App.vue';
import store from '@/store';
import router from './router';
import BootstrapTexts from './constants/BootstrapTexts';
import Subtitle1 from '@/components/typography/Subtitle1.vue';
import Heading3 from '@/components/typography/Heading3.vue';
import Heading2 from '@/components/typography/Heading2.vue';
import BodyText1 from '@/components/typography/BodyText1.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BrandButton from '@/components/common/BrandButton.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import Subtitle2 from '@/components/typography/Subtitle2.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import Heading4 from '@/components/typography/Heading4.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseAvatar from '@/components/base/BaseAvatar.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseCardSection from '@/components/base/BaseCardSection.vue';
import InputWithValidation from '@/components/common/InputWithValidation.vue';
import Heading1 from '@/components/typography/Heading1.vue';


const app = createApp(App);
app.use(store);
app.use(router);
app.use(Quasar, {
	components,
	plugins: {
		Notify
	},
	config: {
		notify: {
			color: 'teal-10',
			textColor: 'white',
		}
	}
});
app.use(vuexI18n.plugin, {
	store,
	config: {
		moduleName: 'i18n',
		onTranslationNotFound: (locale: string, key: string, defaultValue: string) => {
			// TODO
			// store.dispatch(Actions.FLAG_MISSING_TRANSLATION, {
			//     key,
			//     defaultValue,
			// });
		},
	},
});

// Locale
app.config.globalProperties.$i18n.add('en');
app.config.globalProperties.$i18n.set('en', BootstrapTexts);

// Global components registration
app.component('subtitle-1', Subtitle1);
app.component('heading-3', Heading3);
app.component('heading-2', Heading2);
app.component('body-text-1', BodyText1);
app.component('subtitle-2', Subtitle2);
app.component('heading-4', Heading4)
app.component('heading-1', Heading1)

app.component('base-input', BaseInput);
app.component('base-button', BaseButton);
app.component('brand-button', BrandButton);
app.component('base-card', BaseCard);
app.component('base-icon', BaseIcon);
app.component('base-modal', BaseModal); 
app.component('base-avatar', BaseAvatar); 
app.component('base-image', BaseImage); 
app.component('base-card-section', BaseCardSection); 
app.component('input-with-validation', InputWithValidation);

axios.interceptors.request.use((request) => {
	if (!store.state.Loading.loadingStatus && !store.state.Loading.loadingLock) {
		store.commit(Mutations.SET_LOADING_STATUS, true);
	}

	return request;
});

axios.interceptors.response.use((response) => {
	store.commit(Mutations.SET_LOADING_STATUS, false);
	return response;

}, (error) => {
	store.commit(Mutations.SET_LOADING_STATUS, false);

	if (error.response && error.response.status === 401) {
		store.dispatch(Actions.LOG_OUT);
	}

	return Promise.reject(error);
});

app.mount('#app');
