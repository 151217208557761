export default class FacilityPhotoData {
    public id: number;
    public order: number;
    public storageGUID: string;
    public base64: string;
    public createdAt: string;
    public updatedAt: string;
    public deletedAt: string;

    constructor(
    	id: number,
    	order: number,
    	storageGUID: string,
    	base64: string,
    	createdAt: string,
    	updatedAt: string,
    	deletedAt: string,
    ) {
    	this.id          = id;
    	this.order       = order;
    	this.storageGUID = storageGUID;
    	this.base64      = base64;
    	this.createdAt   = createdAt;
    	this.updatedAt   = updatedAt;
    	this.deletedAt   = deletedAt;
    }

    get formattedBase64(): string {
    	return this.base64.includes('data:image/jpeg;base64,') ? this.base64 : 'data:image/jpeg;base64,' + this.base64;
    }
}
