import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a43def9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-layout-header" }
const _hoisted_2 = { class: "auth-layout-header__right-side-container" }
const _hoisted_3 = { class: "auth-layout-header__language" }
const _hoisted_4 = { class: "auth-layout-header__avatar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_avatar = _resolveComponent("base-avatar")!
  const _component_menu_item = _resolveComponent("menu-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_base_avatar, {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.showLanguageMenu = !$setup.showLanguageMenu), ["stop"])),
          src: `/img/flags/${$setup.currentLocalizationKey}.svg`,
          size: "30px"
        }, null, 8, ["src"]),
        _createVNode(_component_q_menu, {
          modelValue: $setup.showLanguageMenu,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showLanguageMenu) = $event)),
          square: "",
          anchor: "bottom right",
          self: "top right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_list, {
              class: "auth-layout-header__language__menu-list",
              style: {"min-width":"150px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.availableLanguages, (item) => {
                  return (_openBlock(), _createBlock(_component_menu_item, {
                    key: item.id,
                    hasActiveState: $setup.currentLocalizationKey === item.key,
                    onClick: ($event: any) => ($setup.onLanguageSelected(item.key))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.local_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["hasActiveState", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_base_avatar, {
          src: $setup.profileImage,
          size: "30px"
        }, null, 8, ["src"]),
        _createVNode(_component_q_menu, {
          modelValue: $setup.showProfileMenu,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showProfileMenu) = $event)),
          square: "",
          anchor: "bottom right",
          self: "top right"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_list, {
              class: "auth-layout-header__avatar__menu-list",
              style: {"min-width":"150px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_menu_item, {
                  to: "/profile",
                  icon: "user",
                  hasActiveState: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('general.profile', 'Profile')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_menu_item, {
                  onClick: $setup.logout,
                  icon: "sign-in",
                  hasActiveState: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('navigation.log-out', 'Log out')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}