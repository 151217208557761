import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b24f0ea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_Layout, { key: $setup.currentLocalizationKey }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }))
  ]))
}