
import { onMounted, computed, ComputedRef, ref } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import Actions from '@/store/actions';
import FacilityData, { bookingTypes } from '@/models/sharedFacilities/FacilityData';
import BookingsSideBar from '@/views/Bookings/components/BookingsSideBar.vue';
import WithRightSideBar from '@/layout/pageLayouts/WithRightSideBar.vue';
import SharedFacilityDetailImages from './components/SharedFacilityDetailImages.vue';
import useTextUtils from '@/composables/useTextUtils';
import Mutations from '@/store/mutations';
import BookingModal from '@/views/SharedFacility/components/BookingModal.vue';

export default {
    components: {
        BookingsSideBar,
        WithRightSideBar,
        SharedFacilityDetailImages,
        BookingModal,
    },
    setup(){
        const store = useStore();
        const router = useRouter();
        const { filterText } = useTextUtils();

        const bookingModal = ref(null);

        onMounted(() => {
            if (router.currentRoute.value.params.id){
                store.commit(Mutations.SET_USER_SHARED_FACILITY, null);
                store.dispatch(Actions.FETCH_USER_SHARED_FACILITY, router.currentRoute.value.params.id)
            }
        })	

        function onBookFacility() {
            bookingModal.value = true;
        }

        const facility: ComputedRef<FacilityData> = computed(() => {
            return store.getters['SharedFacilities/getUserSelectedFacility'];
		})
		
		const filteredName = computed(() => facility.value?.name ? filterText(facility.value.name) : '');
		const filteredDescription = computed(() => facility.value?.description ? filterText(facility.value.description) : '');
        const bookingType = computed(() => {
			if(facility.value.bookingType === bookingTypes.HOURLY_BOOKING) return 'Hourly'
			if(facility.value.bookingType === bookingTypes.BLOCK_BOOKING) return facility.value.block.duration + ' minute slot'
			if(facility.value.bookingType === bookingTypes.DAILY_BOOKING) return 'Daily'
			return 'Booking type not specified'; 
		})

        return {
            facility,
            filteredName,
            filteredDescription,
            bookingType,
            onBookFacility,
            bookingModal
        }
    }
}
