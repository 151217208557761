
import dayjs from 'dayjs';

export default{
	props: {
		background: {
			type: String,
			required: true
		},
        date: {
			type: String,
			required: true
		}
	},
    setup() {
       function getLocalShortDate(date: string) {
			return dayjs.utc(date).format('ll');
		}

        return {
            getLocalShortDate
        }
    }
}
