
import { computed } from 'vue';

export default {
	props: {
		src: {
			type: String
		}
	},
    
	setup(props) {
		const imageSrc = computed(() => props.src ?? '/img/user/no-image.png');

		return {
			imageSrc,
		}
	} 
}
