import { computed, ref, watch, reactive, onMounted, Ref, shallowRef } from 'vue';
import { useRouter } from 'vue-router';
import { omit } from 'lodash';

import BookingInfo from '@/views/Bookings/modals/BookingInfo.vue';
import BookingInfoDetails from '@/views/Bookings/modals/BookingInfoDetails.vue';
import CancelBooking from '@/views/Bookings/modals/CancelBooking.vue';
import NotificationDetailModal from '@/views/Dashboard/components/modals/NotificationDetailModal.vue';

const modalComponentMap = {
	notification: {
		component: NotificationDetailModal,
		queryParamName: 'notificationId'
	},
	booking: {
		component: BookingInfo,
		queryParamName: 'selectedBooking'
	},
	bookingDetail: {
		component: BookingInfoDetails,
		queryParamName: 'bookingId'
	},
	cancelBooking: {
		component: CancelBooking,
		queryParamName: 'cancelBookingId'
	}
}

const selectedModalKey: Ref<keyof typeof modalComponentMap | null> = ref(null);
const modalComponent = shallowRef(null);

export default function useGlobalModal() {
	const router = useRouter();
	const modalQueryParams = Object.values(modalComponentMap).map(({ queryParamName }) => queryParamName);

	function getModalKeyFromQueryParams(queryParams): string {
		if (!queryParams) {
			return null;
		}

		const modalQueryName = Object.entries(queryParams).find(([key, value]) => {
			return modalQueryParams.includes(key) && String(value).match(/\d+/);
		})

		if (!modalQueryName) {
			return null;
		}

		const modalKey = Object.entries(modalComponentMap).find(([_, value]) => {
			return value.queryParamName === modalQueryName[0];
		})

		return modalKey[0] ?? null;
	}

	function updateSelectedModal(modalKey) {
		selectedModalKey.value = modalKey;
		modalComponent.value = modalComponentMap[modalKey]?.component;
	}

	function navigateToModal(id: string, type: keyof typeof modalComponentMap) {
		const queryParamName = modalComponentMap[type].queryParamName;
		const pathName = router.currentRoute.value.name;
		const queryParams = router.currentRoute.value.query;
		const omittedQueryParams = omit(queryParams, modalQueryParams);
		router.push({ name: pathName, query: { ...omittedQueryParams, [queryParamName]: id } })
	}

	watch(() => router.currentRoute.value.query, (value) => {
		const newModalKey = getModalKeyFromQueryParams(value);
		if (newModalKey !== selectedModalKey.value) {
			updateSelectedModal(newModalKey);
		}
	}, { deep: true })

	onMounted(() => {
		const modalKey = getModalKeyFromQueryParams(router.currentRoute.value.query);
		if (modalKey) {
			updateSelectedModal(modalKey);
		}
	})

	const showModal = computed({
		get() {
			return selectedModalKey.value !== null;
		},
		set() {
			router.back();
		}
	})
	return { showModal, modalComponent, navigateToModal }
}