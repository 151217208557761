export default class IdentityData {
    public parent: number;
    public context: number;
    public logo: string;
    public name: string;
    public role: string;
    public userType: string;
    public notificationsCount: number;

    constructor(
    	parent: number,
    	context: number,
    	logo: string,
    	name: string,
    	role: string,
    	userType: string,
    ) {
    	this.parent             = parent;
    	this.context            = context;
    	this.logo               = logo;
    	this.name               = name;
    	this.role               = role;
    	this.userType           = userType;
    	this.notificationsCount = 0;
    }

    public updateNotifications(notificationsCount: number) {
    	this.notificationsCount = notificationsCount;
    }

    public incrementNotifications() {
    	this.notificationsCount = this.notificationsCount + 1;
    }

    public isTheSameIdentity(identity: any): boolean {
    	return (
    		this.context  === identity.context  &&
            this.name     === identity.name     &&
            this.role     === identity.role     &&
            this.userType === identity.userType
    	);
    }
}
