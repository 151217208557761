import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0901faf7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-card-header__left-container" }
const _hoisted_2 = { class: "event-card-header__right-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_heading_4 = _resolveComponent("heading-4")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_subtitle_2 = _resolveComponent("subtitle-2")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createBlock(_component_q_item, { class: "event-card-header" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_item_section, { avatar: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_user_avatar, {
              userId: $props.author.id,
              size: "36px"
            }, null, 8, ["userId"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_item_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_heading_4, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.author.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_subtitle_1, { class: "event-card-header__label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.author.isTenant ? _ctx.$t('general.resident', 'Resident') : $props.author.staffTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_subtitle_2, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.getLocalDate($props.createdDateTime)), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}