import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a26bd08c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "broadcast-card-info__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_like_counter = _resolveComponent("like-counter")!
  const _component_body_text_1 = _resolveComponent("body-text-1")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_component_q_card_section, { class: "broadcast-card-info" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_heading_3, { innerHTML: $setup.filteredTitle }, null, 8, ["innerHTML"]),
        _createVNode(_component_like_counter, {
          onToggle: _withModifiers($setup.toggle, ["stop"]),
          likeCount: $setup.likeCount,
          hasLike: $setup.hasLike
        }, null, 8, ["onToggle", "likeCount", "hasLike"])
      ]),
      _createVNode(_component_body_text_1, {
        class: "broadcast-card-info__text",
        innerHTML: $setup.filteredText
      }, null, 8, ["innerHTML"])
    ]),
    _: 1
  }))
}