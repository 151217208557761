import { setState } from '@/plugins/storage';
import Mutations from '@/store/mutations';

const shouldSkipCache = (mutation: any) => {
	// Your business logic here

	switch (mutation.type) {
	// case Mutations.UPDATE_AUTH_DATA:
	case Mutations.UPDATE_PERSON_DATA: 
	case Mutations.SET_USER_IDENTITY:
		return false;
		break;
	default:
		break;
	}
  
	return true;
};

const plugin = (store: any) => {
	store.subscribe((mutation: any, state: any) => {
		if (!shouldSkipCache(mutation)) {
			setState(state).catch((err) => console.warn('failed to cache state', err));
		}
	});
};

export default plugin;
