import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a5d7543"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "booking-modal__header-container" }
const _hoisted_2 = { class: "booking-modal__header-container-right" }
const _hoisted_3 = { class: "booking-modal__header-info-container" }
const _hoisted_4 = { class: "booking-modal__calendar-container" }
const _hoisted_5 = { class: "booking-modal__block-info" }
const _hoisted_6 = {
  key: 0,
  class: "booking-modal__chip-container"
}
const _hoisted_7 = { class: "chip__content" }
const _hoisted_8 = { class: "booking-modal__summary-container" }
const _hoisted_9 = { class: "summary-line" }
const _hoisted_10 = {
  key: 0,
  class: "summary-line"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "booking-modal__header-container" }
const _hoisted_13 = { class: "booking-modal__success-container" }
const _hoisted_14 = { class: "booking-modal__time-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_base_image = _resolveComponent("base-image")!
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_base_card_section = _resolveComponent("base-card-section")!
  const _component_separator = _resolveComponent("separator")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_heading_1 = _resolveComponent("heading-1")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_base_modal, null, {
    default: _withCtx(() => [
      (!$setup.showSuccessInfo)
        ? (_openBlock(), _createBlock(_component_base_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_base_card_section, { class: "booking-modal__section" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _withDirectives(_createVNode(_component_q_btn, {
                      flat: "",
                      round: "",
                      icon: "far fa-close",
                      size: "sm"
                    }, null, 512), [
                      [_directive_close_popup]
                    ]),
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_base_image, {
                        src: $props.facility.thumbnail,
                        class: "booking-modal__image"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_heading_2, { class: "booking-modal__header" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.booking', 'Booking')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_subtitle_1, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.filteredName), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_q_date, {
                      "first-day-of-week": "1",
                      modelValue: $setup.date,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.date) = $event)),
                      minimal: "",
                      class: "booking-modal__calendar",
                      options: $setup.isDayBookable
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_separator),
              ($setup.bookingType !== $setup.bookingTypes.DAILY_BOOKING)
                ? (_openBlock(), _createBlock(_component_base_card_section, {
                    key: 0,
                    class: "booking-modal__section"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_heading_3, { class: "booking-modal__available-times-header" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.available-times', 'Available times')), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        ($setup.minBookableBlocks > 1)
                          ? (_openBlock(), _createBlock(_component_subtitle_1, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.minimum-number-of-slots', 'Minimum number of slots') + ": " + $setup.minBookableBlocks), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      ($setup.selectableOptions.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectableOptions, (item, index) => {
                              return (_openBlock(), _createBlock(_component_q_chip, {
                                disable: $setup.isBooked(item.status),
                                outline: !$setup.isSelected(item.time),
                                clickable: "",
                                onClick: ($event: any) => ($setup.onTimeSelected(item.time)),
                                class: "chip",
                                "text-color": "white",
                                color: "primary",
                                key: index
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, _toDisplayString($setup.getTimeStampFromObj(item.time)), 1)
                                ]),
                                _: 2
                              }, 1032, ["disable", "outline", "onClick"]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createBlock(_component_subtitle_1, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('booking-modal.no-available-times', 'No available times')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_separator),
              _createVNode(_component_base_card_section, { class: "booking-modal__section-small booking-modal__footer" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_base_icon, {
                        class: "summary-line__icon",
                        name: "calendar-lines"
                      }),
                      ($setup.date)
                        ? (_openBlock(), _createBlock(_component_subtitle_1, {
                            key: 0,
                            label: "",
                            class: "booking-modal__day-string"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.getLocalDateFromString($setup.date)), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    ($setup.bookingType !== $setup.bookingTypes.DAILY_BOOKING)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_base_icon, {
                            class: "summary-line__icon",
                            name: "clock"
                          }),
                          ($setup.startTime && $setup.endTime)
                            ? (_openBlock(), _createBlock(_component_subtitle_1, {
                                key: 0,
                                label: "",
                                class: "booking-modal__day-string"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(`${$setup.getTimeStampFromObj($setup.startTime)} - ${$setup.getTimeStampFromObj($setup.endTime)} (${$setup.localDurationString ?? ""})`), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_subtitle_1, {
                                key: 1,
                                label: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.select-time', 'Select time')), 1)
                                ]),
                                _: 1
                              }))
                        ]))
                      : _createCommentVNode("", true),
                    ($setup.dayIsBooked)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createVNode(_component_subtitle_1, { label: "" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.day-is-booked', 'The selected day is booked')), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_brand_button, {
                    onClick: $setup.onBook,
                    disabled: !$setup.canBeBooked
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('general.book-now', 'Book now')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_base_card, { key: 1 }, {
            default: _withCtx(() => [
              ($setup.startTime && $setup.endTime)
                ? (_openBlock(), _createBlock(_component_base_card_section, {
                    key: 0,
                    class: "booking-modal__section"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _withDirectives(_createVNode(_component_q_btn, {
                          flat: "",
                          round: "",
                          icon: "far fa-close",
                          size: "sm"
                        }, null, 512), [
                          [_directive_close_popup]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_heading_1, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.successful-booking', 'Your booking is completed')), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_heading_3, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.getLocalDateFromString($setup.date)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_heading_3, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.getTimeStampFromObj($setup.startTime)) + " - " + _toDisplayString($setup.getTimeStampFromObj($setup.endTime)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_brand_button, { onClick: $setup.redirectToBookings }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('general.my-bookings', 'My Bookings')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}