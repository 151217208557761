
import { useField } from 'vee-validate';
export default {
	name: 'InputWithValidation',
	props: {
		name: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const { errorMessage, value } = useField(props.name);

		return {
			errorMessage,
			value,
		};
	},
};
