const BootstrapTexts = {
	'wiating': {
		type_a: 'Type A',
		type_b: 'Type B',
		type_c: 'Type C',
		type_d: 'Type D',
		type_e: 'Type E',
		type_f: 'Type F',
	},
	'waiting-list.venteliste': 'Venteliste',
	'general.email-address': 'Email address',
	'general.password': 'Password',
	'login.log-in': 'Log in',
	'login.enter-your-credentials': 'Enter your credentials below to log in.',
	'login.forgot-my-password': 'I forgot my password.',
	'login.forgot-password': 'Forgot your password?',
	'login.new-user': 'New user?',
};

export default BootstrapTexts;
