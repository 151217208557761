import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10e20f16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_subtitle_2 = _resolveComponent("subtitle-2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_icon, {
      name: "calendar-lines",
      class: "event-time__icon"
    }),
    _createVNode(_component_subtitle_2, { label: "" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.dateString), 1)
      ]),
      _: 1
    })
  ]))
}