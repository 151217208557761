export default class AuthData {
    public accessToken: string;
    public refreshToken: string;
    public tokenType: string;
    public tokenExpiration: number;

    constructor(
    	accessToken: string,
    	refreshToken: string,
    	tokenType: string,
    	tokenExpiration: number,
    ) {
    	this.accessToken = accessToken;
    	this.refreshToken = refreshToken;
    	this.tokenType = tokenType;
    	this.tokenExpiration = tokenExpiration;
    }
}
