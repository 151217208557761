/* eslint-disable */

export default class ErrorResponse {
    public statusCode: number;
    public message: string;
    public timestamp: number;

    constructor(
        statusCode: number,
        message: string = '',
    ) {
        this.statusCode = statusCode;
        this.message = message;
        this.timestamp = Date.now();
    }      
}
