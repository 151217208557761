export default class NotificationType {
    public id: number;
    public key: string;
    public description: string;

    constructor(
    	id: number,
    	key: string,
    	description: string,
    ) {
    	this.id          = id;
    	this.key         = key;
    	this.description = description;
    }

    get isBroadcast(): boolean {
    	return this.key === 'information';
    }

    get isEvent(): boolean {
    	return this.key === 'event';
    }
}
