
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import SharedFacilityCardImage from './SharedFacilityCardImage.vue';
import useTextUtils from '@/composables/useTextUtils';
import FacilityData, { bookingTypes } from '@/models/sharedFacilities/FacilityData';
import BrandButton from '@/components/common/BrandButton.vue';
   
export default {
	components: {
		SharedFacilityCardImage,
		BrandButton,
	},
	props: {
		facility: {
			type: FacilityData,
			required: true
		}
	},
	setup(props){
		const { filterText } = useTextUtils();
		const router = useRouter();
		
		const filteredName = computed(() => filterText(props.facility.name))
		const filteredDescription = computed(() => filterText(props.facility.description))
		const bookingType = computed(() => {
			if(props.facility.bookingType === bookingTypes.HOURLY_BOOKING) return 'Hourly'
			if(props.facility.bookingType === bookingTypes.BLOCK_BOOKING) return props.facility.block.duration + ' minute slot'
			if(props.facility.bookingType === bookingTypes.DAILY_BOOKING) return 'Daily'
			return 'Booking type not specified'; 
		})

		function redirectToDetails() {
			router.push({ name: 'FacilityDetail', params: { id: props.facility.id } })
		}

		return {
			filteredName,
			filteredDescription,
			bookingType,
			redirectToDetails
		}
	}
	
}
