

export default {
	props: {
		label: {
			type: Boolean,
			default: false
		}
	}
}
