

export default {
	props: {
		likeCount: {
			type: Number,
			default: 0
		},
		hasLike: {
			type: Boolean,
			default: false
		}
	},
	emits: [
		'toggle'
	],
	setup (_, context) {
		const onToggle = (event) => {
			context.emit('toggle', event)
		}

		return {
			onToggle
		}
	} 
}
