
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import partition from 'lodash/partition';
import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';

import BookingItem from './components/BookingItem.vue';
import BookingData from '@/models/BookingData';
import Actions from '@/store/actions';
import WithRightSideBar from '@/layout/pageLayouts/WithRightSideBar.vue';
import Separator from '@/components/common/Separator.vue';

export default {
	components: {
		BookingItem,
		WithRightSideBar,
		Separator,
	},
	setup() {
		const store = useStore();

		const bookings = computed(() => store.getters['SharedFacilities/getUserFacilitiesBookings']);
		
		const partitionedBookings = computed(() => {
			const filteredData = bookings.value.filter((booking: BookingData) => !booking.deletedAt)
			return partition(filteredData, (booking) => dayjs().isBefore(dayjs(booking.startTime)));
		});

		const upcomingBookings = computed(() => orderBy(partitionedBookings.value[0], [function(booking) {return booking.startTimeTimestamp}], ['asc'] ))
		const previousBookings = computed(() => orderBy(partitionedBookings.value[1], [function(booking) {return booking.startTimeTimestamp}], ['desc'] ))

		onMounted(() => {
			store.dispatch(Actions.FETCH_USER_FACILITIES_BOOKINGS);
		})

		return { upcomingBookings, previousBookings };
	},
};
