import { createStore } from 'vuex'
import axios from 'axios';

// Modules
import Viewport from '@/store/modules/Viewport';
import User from '@/store/modules/User';
import Identities from '@/store/modules/Identities';
import Loading from '@/store/modules/Loading';
import TenantNotifications from '@/store/modules/TenantNotifications';
import SharedFacilities from '@/store/modules/SharedFacilities';
import Localization from './modules/Localization';

// Plugins
import cache from '@/store/plugins/cache';

export default createStore({
	modules: {
		Viewport,
		User,
		Identities,
		TenantNotifications,
		Loading,
		SharedFacilities,
		Localization,
	},
	plugins: [
		cache
	],
})


