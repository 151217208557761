import endpoints from '@/api/endpoints';
import axios from 'axios';
import { onMounted, computed, ComputedRef, reactive } from 'vue'

type userImage = {
    userId: number;
    imageData: string | null;
    imageDataType: 'thumbnail';
}

const imageArray: userImage[] = reactive([]);

export default function useUserImage (userId: number, imageType: 'thumbnail' = 'thumbnail') {

	const image: ComputedRef<userImage | null> = computed(() => imageArray.find((image) => {
		return image.userId === userId && image.imageDataType === imageType;
	}));

	const imageSrc:  ComputedRef<string | null> = computed(() => image.value?.imageData ?? null)

	onMounted(async () => {
		const cachedValue = imageArray.find((image) => {
			return image.userId === userId && image.imageDataType === imageType;
		})
		if(!cachedValue){
			const newImageData: userImage = reactive({
				userId: userId,
				imageData: null,
				imageDataType: imageType,
			})
			imageArray.push(newImageData);
			try {
				const response = await axios.get(endpoints.Users.USER_PROFILE_IMAGE(userId, imageType))
				newImageData.imageData = response.data;
			} catch(error) {
				console.log('Could not fetch user image for userid: ' + userId)
				const objectIndex = imageArray.indexOf(newImageData);
				if (objectIndex !== -1){
					imageArray.splice(imageArray.indexOf(newImageData), 1)
				}
			}
		} 
	})

	return {
		imageSrc
	}
}