const Mutations = {
	// User
	RESET_USER_STORE_STATE: 'User/RESET_USER_STORE_STATE',
	UPDATE_AUTH_DATA: 'User/UPDATE_AUTH_DATA',
	UPDATE_PERSON_DATA: 'User/UPDATE_PERSON_DATA',
	PASSWORD_RESET_SUCCESS: 'User/PASSWORD_RESET_SUCCESS',
	PASSWORD_RESET_FAILURE: 'User/PASSWORD_RESET_FAILURE',
	RESET_RESET_PASSWORD: 'User/RESET_RESET_PASSWORD',
	SET_CURRENT_PROFILE_IMAGE: 'User/SET_CURRENT_PROFILE_IMAGE',
	RESET_CURRENT_PROFILE_IMAGE: 'User/RESET_CURRENT_PROFILE_IMAGE',

	// Viewport
	ADD_ERROR_RESPONSE: 'Viewport/ADD_ERROR_RESPONSE',

	// Loading
	SET_LOADING_STATUS: 'Loading/SET_LOADING_STATUS',
	RESET_LOADING_STORE_STATE: 'Loading/RESET_LOADING_STORE_STATE',

	// Localization
	SET_ALL_LANGUAGES: 'Localization/SET_ALL_LANGUAGES',
	SET_LANGUAGE_ALL_TEXTS: 'Localization/SET_LANGUAGE_ALL_TEXTS',
	SET_LANGUAGE_OPTION: 'Localization/SET_LANGUAGE_OPTION',
	SET_LOCALIZATION_SEARCH_OPTION: 'Localization/SET_LOCALIZATION_SEARCH_OPTION',
	UPDATE_TEXT_CONTENT: 'Localization/UPDATE_TEXT_CONTENT',
	SET_LOCALIZATION_KEY: 'Localization/SET_LOCALIZATION_KEY',
	ADD_LANGUAGE_KEY: 'Localization/ADD_LANGUAGE_KEY',
	SET_ADD_LANGUAGE_VISIBILITY: 'Localization/SET_ADD_LANGUAGE_VISIBILITY',
	SET_ADD_LANGUAGE_KEY_VISIBILITY: 'Localization/SET_ADD_LANGUAGE_KEY_VISIBILITY',
	SET_IMPORT_LANGUAGE_CSV_VISIBILITY: 'Localization/SET_IMPORT_LANGUAGE_CSV_VISIBILITY',

	// Identities
	SET_IDENTITIES: 'Identities/SET_IDENTITIES',
	RESET_IDENTITIES_STORE_STATE: 'Identities/RESET_IDENTITIES_STORE_STATE',
	SET_USER_IDENTITY: 'Identities/SET_USER_IDENTITY',
	SET_IDENTITIES_NOTIFICATIONS: 'Identities/SET_IDENTITIES_NOTIFICATIONS',
	UPDATE_IDENTITY_NOTIFICATIONS: 'Identities/UPDATE_IDENTITY_NOTIFICATIONS',

	// Dashboard:
	ADD_DASHBOARD_NOTIFICATIONS: 'TenantNotifications/ADD_DASHBOARD_NOTIFICATIONS',
	RESET_DASHBOARD_NOTIFICATIONS: 'TenantNotifications/RESET_DASHBOARD_NOTIFICATIONS',
	SET_DASHBOARD_PAGE: 'TenantNotifications/SET_DASHBOARD_PAGE',
	SET_LAST_DASHBOARD_PAGE: 'TenantNotifications/SET_LAST_DASHBOARD_PAGE',
	SET_IS_LOADING_NOTIFICATIONS: 'TenantNotifications/SET_IS_LOADING_NOTIFICATIONS',
	SET_DASHBOARD_NOTIFICATIONS: 'TenantNotifications/SET_DASHBOARD_NOTIFICATIONS',
	SET_ACTIVE_NOTIFICATION: 'TenantNotifications/SET_ACTIVE_NOTIFICATION',
	RESET_ACTIVE_NOTIFICATION: 'TenantNotifications/RESET_ACTIVE_NOTIFICATION',

	//Shared facilities
	SET_USER_SHARED_FACILITIES: 'SharedFacilities/SET_USER_SHARED_FACILITIES',
	SET_USER_SHARED_FACILITY: 'SharedFacilities/SET_USER_SHARED_FACILITY',
	SET_USER_FACILITIES_BOOKINGS: 'SharedFacilities/SET_USER_FACILITIES_BOOKINGS',
	RESET_USER_SHARED_FACILITIES: 'SharedFacilities/RESET_USER_SHARED_FACILITIES',
	RESET_USER_FACILITIES_BOOKINGS: 'SharedFacilities/RESET_USER_FACILITIES_BOOKINGS',
	SET_USER_SELECTED_BOOKING: 'SharedFacilities/SET_USER_SELECTED_BOOKING',
	SET_USER_SELECTED_FACILITY: 'SharedFacilities/SET_USER_SELECTED_FACILITY',
	SET_SHARED_FACILITIES_PHOTOS: 'SharedFacilities/SET_SHARED_FACILITIES_PHOTOS',
	RESET_SHARED_FACILITIES_PHOTOS: 'SharedFacilities/RESET_SHARED_FACILITIES_PHOTOS',
	DELETE_USER_BOOKING: 'SharedFacilities/DELETE_USER_BOOKING',
};

export default Mutations;