

export default {
	props: {
		image: {
			type: String,
			default: ''
		}
	}
}
