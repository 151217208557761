const Actions = {

	// User
	LOG_IN: 'User/LOG_IN',
	LOG_OUT: 'User/LOG_OUT',
	REFRESH_USER_DATA: 'User/REFRESH_USER_DATA',
	CHECK_AUTHENTICATION: 'User/CHECK_AUTHENTICATION',
	UPDATE_USER: 'User/UPDATE_USER',
	RESET_PASSWORD: 'User/RESET_PASSWORD',
	SAVE_PROFILE_IMAGE: 'User/SAVE_PROFILE_IMAGE',
	DELETE_PROFILE_IMAGE: 'User/DELETE_PROFILE_IMAGE',

	// Localization
	FETCH_ALL_LANGUAGES: 'Localization/FETCH_ALL_LANGUAGES',
	FETCH_LANGUAGE_ALL_TEXTS: 'Localization/FETCH_LANGUAGE_ALL_TEXTS',
	SAVE_LANGUAGE_TEXT: 'Localization/SAVE_LANGUAGE_TEXT',
	ADD_NEW_LANGUAGE: 'Localization/ADD_NEW_LANGUAGE',
	ADD_LANGUAGE_NEW_KEY: 'Localization/ADD_LANGUAGE_NEW_KEY',
	IMPORT_LANGUAGE_CSV: 'Localization/IMPORT_LANGUAGE_CSV',
	GLOBAL_CHANGE_LANGUAGE: 'Localization/GLOBAL_CHANGE_LANGUAGE',
	FLAG_MISSING_TRANSLATION: 'Localization/FLAG_MISSING_TRANSLATION',
	SEND_MISSING_TRANSLATIONS: 'Localization/SEND_MISSING_TRANSLATIONS',
	UPDATE_USER_LANGUAGE: 'Localization/UPDATE_USER_LANGUAGE',

	// Identities
	FETCH_IDENTITIES: 'Identities/FETCH_IDENTITIES',
	FETCH_IDENTITIES_NOTIFICATIONS: 'Identities/FETCH_IDENTITIES_NOTIFICATIONS',
	SELECT_IDENTITY: 'Identities/SELECT_IDENTITY',
	SWITCH_IDENTITY: 'Identities/SWITCH_IDENTITY',
	SET_IDENTITY: 'Identities/SET_IDENTITY',
	INIT_IDENTITIES_SOCKET: 'Identities/INIT_IDENTITIES_SOCKET',

	// Notifications:
	FETCH_DASHBOARD_NOTIFICATIONS: 'TenantNotifications/FETCH_DASHBOARD_NOTIFICATIONS',
	SET_NOTIFICATION_USER_LIKE: 'TenantNotifications/SET_NOTIFICATION_USER_LIKE',
	FETCH_TENANT_NOTIFICATION: 'TenantNotifications/FETCH_TENANT_NOTIFICATION',

	//Shared facilities
	FETCH_USER_SHARED_FACILITIES: 'SharedFacilities/FETCH_USER_SHARED_FACILITIES',
	FETCH_USER_SHARED_FACILITY: 'SharedFacilities/FETCH_USER_SHARED_FACILITY',
	FETCH_SHARED_FACILITIES_PHOTOS: 'SharedFacilities/FETCH_SHARED_FACILITIES_PHOTOS',
	FETCH_USER_FACILITIES_BOOKINGS: 'SharedFacilities/FETCH_USER_FACILITIES_BOOKINGS',
	CANCEL_USER_BOOKING: 'SharedFacilities/CANCEL_USER_BOOKING',
	SUBMIT_BOOKING: 'SharedFacilities/SUBMIT_BOOKING',
};

export default Actions;