import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67717f0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "menu-item__icon-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_icon = _resolveComponent("base-icon")!
  const _component_Subtitle1 = _resolveComponent("Subtitle1")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createBlock(_component_q_item, {
    clickable: "",
    class: "menu-item",
    active: $props.hasActiveState,
    "active-class": "menu-item--active"
  }, {
    default: _withCtx(() => [
      ($props.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_base_icon, { name: $props.icon }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Subtitle1, { class: "menu-item__text menu-item__text--active" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["active"]))
}