
import BookingData from '@/models/BookingData';
import dayjs from 'dayjs';
import BookingItemImage from '@/views/Bookings/components/BookingItemImage.vue';

export default{
	props: {
		booking: {
			type: BookingData,
			required: true
		}
	},
    components: {
        BookingItemImage,
    },
    setup() {
        function getLocalTime(date: string) {
			return dayjs.utc(date).format('LT');
		}

        return {
            getLocalTime,
        }
    }
}
