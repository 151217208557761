
import { onBeforeMount, onMounted } from '@vue/runtime-core'
import Layout from '@/layout/Layout.vue'

import Actions from '@/store/actions';
import { useStore } from 'vuex';
import useI18n from '@/composables/useI18n';

export default {
	components: {
		Layout,
	},
    setup(){
        const { currentLocalizationKey } = useI18n();
        const store = useStore();

        onMounted(() => {
            store.dispatch(Actions.FETCH_ALL_LANGUAGES);
        })

        return {
            currentLocalizationKey,
        }
    }
}

