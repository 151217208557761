import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "shared-facility-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shared_facility_card = _resolveComponent("shared-facility-card")!
  const _component_bookings_side_bar = _resolveComponent("bookings-side-bar")!
  const _component_with_right_side_bar = _resolveComponent("with-right-side-bar")!

  return (_openBlock(), _createBlock(_component_with_right_side_bar, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.facilities, (facility) => {
          return (_openBlock(), _createBlock(_component_shared_facility_card, {
            class: "shared-facility-card",
            key: facility.id,
            facility: facility
          }, null, 8, ["facility"]))
        }), 128))
      ])
    ]),
    sidebar: _withCtx(() => [
      _createVNode(_component_bookings_side_bar)
    ]),
    _: 1
  }))
}