
import { computed } from 'vue';

import LikeCounter from './social/LikeCounter.vue'
import useDateUtils from '@/composables/useDateUtils';
import EventTime from './social/EventTime.vue';
//import CommentsCounter from './social/CommentCounter.vue'
import useNotificationLike from '@/composables/useNotificationLike';
import DashboardNotification from '@/models/notifications/DashboardNotification';
import useTextUtils from '@/composables/useTextUtils';

export default {
	components: {
		LikeCounter,
		EventTime,
		//CommentsCounter,
	},
	props: {
		notification: {
			type: DashboardNotification,
			required: true
		}
	},
	setup(props) { 
		const { getLocalDateTime } = useDateUtils();
		const likesRecipients = computed(() => props.notification.likesRecipients)
		const { hasLike, likeCount, toggle } = useNotificationLike(props.notification.id, likesRecipients)
		const { filterText } = useTextUtils();

		const filteredText = computed(() => filterText(props.notification.text))
		const filteredTitle = computed(() => filterText(props.notification.title))

		return {
			getLocalDateTime,
			hasLike,
			likeCount,
			toggle,
			filteredText,
			filteredTitle,
		}
	}

}
