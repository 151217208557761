
import { useStore } from 'vuex';
import Actions from '@/store/actions';
import { useRouter } from 'vue-router';
import { Field, Form } from 'vee-validate';
import AuthCard from './AuthCard.vue';
import * as yup from 'yup';
import { useQuasar } from 'quasar';
import { ref } from '@vue/reactivity';

export default {
	components: {
		Field,
		Form,
		AuthCard,
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const $q = useQuasar();
		const emailMsg = 'Please enter a valid email address.';
		const passwordMsg = 'Please enter a valid password.';
		const schema = yup.object({
			email: yup.string().required(emailMsg).email(emailMsg),
			password: yup.string().required(passwordMsg),
		});
		const isPwd = ref(true);
		const initialValues = {
			email: '',
			password: '',
		};

		async function login(values) {
			const redirectTo = router.currentRoute.value.query?.redirectTo;
			await store
				.dispatch(Actions.LOG_IN, {
					email: values.email,
					password: values.password,
				})
				.then(() => {
					const to = typeof redirectTo == 'string' ? redirectTo : '/';
					router.push(to);
				})
				.catch(() => {
					$q.notify({
						message: 'Email or password invalid!',
						color: 'red',
					});
				});
		}

		return {
			schema,
			isPwd,
			initialValues,
			login,
		};
	},
};
