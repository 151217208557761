import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';

// Views
import LogIn from '@/views/Authentication/LogIn.vue'
import ResetPassword from '@/views/Authentication/LoginResetPassword.vue'
import Dashboard from '@/views/Dashboard/Dashboard.vue'
import SharedFacilityList from '@/views/SharedFacility/SharedFacilityList.vue'
import BookingList from '@/views/Bookings/BookingList.vue'
import store from '@/store';
import Actions from '@/store/actions';
import SharedFacilityDetail from '@/views/SharedFacility/SharedFacilityDetail.vue';
import Profile from '@/views/Profile/Profile.vue';
import { Platform } from 'quasar';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Dashboard',
		components: {
			default: Dashboard,
		},
		meta: {
			layout: 'auth',
		}
	},
	{
		path: '/facilities',
		name: 'FacilityList',
		components: {
			default: SharedFacilityList,
		},
		meta: {
			layout: 'auth',
		}
	},
	{
		path: '/facilities/:id',
		name: 'FacilityDetail',
		component: SharedFacilityDetail,
		meta: {
			layout: 'auth',
		},
	},
	{
		path: '/facilities/bookings',
		name: 'BookingList',
		components: {
			default: BookingList,
		},
		meta: {
			layout: 'auth',
		},
	},
	{
	    path: '/profile',
		name: 'Profile',
		components: {
			default: Profile,
		},
		meta: {
			layout: 'auth',
		},
	},
	{
		path: '/login',
		name: 'LogIn',
		components: {
			default: LogIn,
		},
		meta: {
			layout: 'standard',
		},
	},
	{
		path: '/reset',
		name: 'ResetPassword',
		components: {
			default: ResetPassword,
		},
		meta: {
			layout: 'standard',
		},
	}
];


const router = createRouter({
	history: createWebHistory(),
	routes: routes,
});

router.beforeEach(async (to, from) => {
	if (!Platform.is.mobile) {
		const isAuthenticate = await store.dispatch(Actions.CHECK_AUTHENTICATION);
		if (!isAuthenticate && to.name !== 'LogIn' && to.name !== 'ResetPassword') {
			if (to.name !== 'Dashboard') {
				return { name: 'LogIn', query: { redirectTo: to.path }}
			}
			return { name: 'LogIn' }
		}

		if (isAuthenticate && (to.name === 'LogIn' || to.name === 'ResetPassword')) return { name: 'Dashboard' }
	}
})

export default router;


