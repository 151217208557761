export const Connection = { 
    port: '',
    apiUrl: '',
    baseUrl: '',
    echoUrl: '',
    socketEnabled: true,
    clockwork: false,
    clockworkSecret: '',

    setUrls() {
        switch (location.hostname) {
            case 'admin.hococo.io':
                this.baseUrl = 'https://api.hococo.io';
                this.echoUrl = 'https://echo.hococo.io';
                break;

            case 'admin-staging.hococo.io':
                this.baseUrl = 'https://api-staging.hococo.io';
                this.echoUrl = 'https://echo-staging.hococo.io';
                break;
            
            case 'app-test.hococo.io':
            case 'admin-test.hococo.io':
                this.baseUrl = 'https://api-test.hococo.io';
                this.echoUrl = 'https://echo-test.hococo.io';
                break;                

            case 'admin-feature-stage.hococo.io':
                this.baseUrl = 'https://api-feature-stage.hococo.io';
                this.echoUrl = 'https://echo-feature-stage.hococo.io';
                break;                                

            case 'admin-dev.hococo.io':
                this.baseUrl = 'https://api-dev.hococo.io';
                this.echoUrl = 'https://echo-dev.hococo.io';
                break;                

            case 'admin.hococo.dev':
                this.baseUrl = 'https://api.hococo.dev';
                this.echoUrl = 'https://echo.hococo.dev';
                break;
            
            case 'app.hococo.dev':
                this.baseUrl = 'https://api.hococo.dev';
                this.echoUrl = 'https://echo.hococo.dev';
                break;

            case 'admin-local.hococo.io':
            default:                
                this.baseUrl = 'https://api-local.hococo.io';
                this.echoUrl = 'https://echo-local.hococo.io';
                break;
        }

        this.apiUrl = this.baseUrl + this.port;
    },
    getApiUrl() {
        if (this.apiUrl === '') {
            this.setUrls();
        }

        return this.apiUrl;
    },
    getEchoUrl() {
        if (this.echoUrl === '') {
            this.setUrls();
        }

        return this.echoUrl;
    },
};
