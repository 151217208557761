
import { Field, Form } from 'vee-validate';
import AuthCard from './AuthCard.vue';
import Actions from '@/store/actions';
import { useQuasar } from 'quasar';
import { useStore } from 'vuex';
import * as yup from 'yup';
import { ref } from '@vue/reactivity';

export default {
  components: {
    Field,
    Form,
    AuthCard,
  },
  setup() {
    const store = useStore();
    const $q = useQuasar();
    const isResetEmailSent = ref(false);
    const emailMsg = 'Please enter a valid email address.';
    const email = ref('');
    const schema = yup.object({
      email: yup.string().required(emailMsg).email(emailMsg),
    });

    const initialValues = {
      email: '',
    };

    async function reset(values) {
      await store
        .dispatch(Actions.RESET_PASSWORD, {
          email: values.email,
        })
        .then(() => {
          email.value = values.email;
          isResetEmailSent.value = true;
        })
        .catch(() => {
          $q.notify({
            message: 'Email invalid!! Please check and retry again!',
            color: 'red',
          });
        });
    }

    // expose to template and other options API hooks
    return {
      schema,
      initialValues,
      isResetEmailSent,
      email,
      reset,
    };
  },
};
