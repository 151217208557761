import {
	QItem,
	QList,
	QBtn,
	QIcon,
	QInput,
	QMenu,
	QCard,
	QCardSection,
	QItemSection,
	QImg,
	QSeparator,
	QDialog,
	QAvatar,
	QCarousel,
	QCarouselSlide,
	QDate,
	QChip,
} from 'quasar';

const components = {
	QItem,
	QList,
	QBtn,
	QIcon,
	QInput,
	QMenu,
	QCard,
	QCardSection,
	QItemSection,
	QImg,
	QSeparator,
	QDialog,
	QAvatar,
	QCarousel,
	QCarouselSlide,
	QDate,
	QChip,
}

export {
	components
};
