import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    dense: "",
    outlined: ""
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})), undefined, true)
        ])
      }
    })
  ]), 1024))
}