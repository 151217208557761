import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_header = _resolveComponent("notification-header")!
  const _component_notification_image = _resolveComponent("notification-image")!
  const _component_separator = _resolveComponent("separator")!
  const _component_broadcast_card_info = _resolveComponent("broadcast-card-info")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { onClick: $setup.redirectToModal }, {
    default: _withCtx(() => [
      _createVNode(_component_notification_header, {
        author: $props.notification.author,
        createdDateTime: $props.notification.createdAt
      }, null, 8, ["author", "createdDateTime"]),
      ($props.notification.hasMedia)
        ? (_openBlock(), _createBlock(_component_notification_image, {
            key: 0,
            hasMedia: $props.notification.hasMedia,
            id: $props.notification.id
          }, null, 8, ["hasMedia", "id"]))
        : (_openBlock(), _createBlock(_component_separator, { key: 1 })),
      _createVNode(_component_broadcast_card_info, { notification: $props.notification }, null, 8, ["notification"])
    ]),
    _: 1
  }, 8, ["onClick"]))
}