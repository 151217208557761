import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_header = _resolveComponent("notification-header")!
  const _component_notification_image = _resolveComponent("notification-image")!
  const _component_separator = _resolveComponent("separator")!
  const _component_notification_info = _resolveComponent("notification-info")!
  const _component_base_card = _resolveComponent("base-card")!

  return ($setup.notification)
    ? (_openBlock(), _createBlock(_component_base_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_notification_header, {
            author: $setup.notification.author
          }, null, 8, ["author"]),
          ($setup.notification.hasMedia)
            ? (_openBlock(), _createBlock(_component_notification_image, {
                key: 0,
                hasMedia: $setup.notification.hasMedia,
                id: $setup.notification.id
              }, null, 8, ["hasMedia", "id"]))
            : (_openBlock(), _createBlock(_component_separator, { key: 1 })),
          _createVNode(_component_notification_info, { notification: $setup.notification }, null, 8, ["notification"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}