
import { useStore } from 'vuex';
import AuthLayoutHeader from '../components/AuthLayoutHeader.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import Heading3 from '@/components/typography/Heading3.vue';
import Subtitle1 from '@/components/typography/Subtitle1.vue';
import BodyText1 from '@/components/typography/BodyText1.vue';
import MenuItem from '../components/MenuItem.vue';
import useGlobalModal from '@/composables/useGlobalModal';
import ProfileInfoCard from '../components/AuthLayoutProfileInfoCard.vue';
import Actions from '@/store/actions';
import { computed, onMounted } from 'vue';

export default {
	components: {
		AuthLayoutHeader,
		BaseCard,
		Heading3,
		Subtitle1,
		BodyText1,
		MenuItem,
		ProfileInfoCard,
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.state.User.personData);
		const { showModal, modalComponent } = useGlobalModal();

		onMounted(() => {
			store.dispatch(Actions.FETCH_USER_FACILITIES_BOOKINGS);
			store.dispatch(Actions.FETCH_USER_SHARED_FACILITIES);
		});

		return {
			user,
			showModal,
			modalComponent,
		};
	},
};
