
import { useStore } from 'vuex';
import Actions from '@/store/actions';
import { useQuasar } from 'quasar';
import { computed } from '@vue/reactivity';
export default {
	setup() {
		const $q = useQuasar();
		const store = useStore();
		const userData = computed(() => {
			return store.state.User.personData;
		});

		const profileImage = computed(() => {
			return userData.value && userData.value.profileImage
				? userData.value.profileImage
				: null;
		});

		function notify(isSucess = true, operation = 'updated') {
			if (isSucess) {
				$q.notify({
					message: `Profile image has been successfully ${operation}`,
				});
			} else {
				$q.notify({
					message: 'Something went wrong! Please try again!',
					color: 'red',
				});
			}
		}

		function saveNewProfileImage(e) {
			const file = e.target.files[0];
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event: any) => {
				store
					.dispatch(Actions.SAVE_PROFILE_IMAGE, event.target.result)
					.then(() => {
						notify();
					})
					.catch(() => {
						notify(false);
					});
			};
		}

		function deleteProfileImage() {
			store
				.dispatch(Actions.DELETE_PROFILE_IMAGE)
				.then(() => {
					notify(true, 'deleted');
				})
				.catch(() => {
					notify(false);
				});
		}

		return { profileImage, saveNewProfileImage, deleteProfileImage };
	},
};
