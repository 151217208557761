
import { ref, watch, computed, PropType } from 'vue';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

import BroadcastCard from './BroadcastCard.vue';
import EventCard from './EventCard.vue';
import useIntersectionObserver from '@/composables/useIntersectionObserver';
import DashboardNotification from '@/models/notifications/DashboardNotification';

export default {
	components: {
		BroadcastCard,
		EventCard,
	},
	props: {
		notifications: {
			type: Array as PropType<DashboardNotification[]>,
			required: true
		}
	},
	setup(props, context) {
		const loadMoreRef = ref(null);

		const { isIntersecting } = useIntersectionObserver(loadMoreRef);

		const sortedNotifications = computed(() => {
			return orderBy(props.notifications, [function(notification) {return dayjs(notification.sortingDate).unix()}], ['desc']) 
		})

		watch(isIntersecting, isIntersecting => {
			if(isIntersecting){
				context.emit('onLoadMore')
			}
		})  

		return {
			loadMoreRef,
			sortedNotifications,
		}
	}
}
