/* eslint-disable */


import UserRoleData from '@/models/UserRoleData';
import AuthorizationCollection from '@/models/AuthorizationCollection';
import Actions from '@/store/actions';
import { ResidentPropertyData } from '@/interfaces/HococoInterfaces';

export default class PersonData {
    public id: number;
    public firstName: string;
    public familyName: string;
    public roleData: UserRoleData | null;
    public roleId: number;
    public address: string;
    public country: string;
    public zipCode: string;
    public phoneNumber: number;
    public firm: string;
    public city: string;
    public email: string;
    public propertyId: number;
    public corporationId: number;
    public isTenant: boolean;
    public functionDescription: string;
    public mainRole: string;
    public hococoStaff: boolean;
    public isTenantContactableByMessage: boolean;
    public isTenantContactableByPhone: boolean;
    public translations: any;

    // Profile images
    public hasProfileImage: boolean = true;
    public profileImage: string;
    public profileImageThumbnail: string;
    public miniatureImage: string = '';
    public fetchedMiniature: boolean = false;
    public hasMiniature: boolean = false;

    public authorizations: AuthorizationCollection = new AuthorizationCollection();

    public residentPropertyData: ResidentPropertyData;

    // Pin
    public hasPin: boolean = false;

    // Features
    public features: string[] = [];
    public staffTitle: string = '';

    constructor(
        id: number = 0,
        firstName: string = '',
        familyName: string = '',
        phoneNumber: number = null,
        address: string = '',
        zipCode: string = '',
        city: string = '',
        email: string = '',
        propertyId: number = null,
        corporationId: number = null,
        isTenant: boolean = true,
        mainRole: string = '',
        hococoStaff: boolean = false,
        isTenantContactableByMessage: boolean = null,
        isTenantContactableByPhone: boolean = null,
        translations: any = [],
    ) {
        this.id = id;
        this.firstName = firstName;
        this.familyName = familyName;
        this.phoneNumber = phoneNumber;
        this.address = address;
        this.zipCode = zipCode;
        this.city = city;
        this.email = email;
        this.country = 'Danmark';
        this.firm = 'BlackMoose ApS';
        this.profileImage = '';
        this.propertyId = propertyId;
        this.corporationId = corporationId;
        this.isTenant = isTenant;
        this.mainRole = mainRole;
        this.hococoStaff = hococoStaff;
        this.isTenantContactableByMessage = isTenantContactableByMessage;
        this.isTenantContactableByPhone = isTenantContactableByPhone;
        this.translations = translations;
    }

    get mainRoleTitle(): string {
        return this.mainRole !== '' ? this.mainRole.replace('-', ' ') : '';
    }

    get name(): string {
        return this.fullName;
    }

    get formattedNumber(): string {
        const phoneNumberString = this.phoneNumber.toString();

        return phoneNumberString;

        return (phoneNumberString.length > 8)
            ? phoneNumberString
            : '+45 ' + phoneNumberString;
    }

    get avatar() {
        return this.miniatureImage;
    }

    get firstInitial(): string {
        return this.firstName.substring(0, 1).toUpperCase();
    }

    get getCity() {
        return this.city;
    }

    get fullName(): string {
        return this.firstName + ' ' + this.familyName;
    }

    get getAddress() {
        return this.address;
    }

    get getCountry() {
        return this.country;
    }

    get getFirstName() {
        return this.firstName.split(' ')[0];
    }

    get getFirstNames() {
        return this.firstName;
    }

    get getZipCode() {
        return this.zipCode;
    }

    get getPhoneNumber() {
        return this.phoneNumber;
    }

    get propertyRole(): string {
        return this.roleData.key;
    }

    get getFirm() {
        return this.firm;
    }

    get hasProfileImageData() {
        return (this.profileImage === null || this.getProfileImageThumbnail === null);
    }

    get getProfileImage() {
        return this.profileImage;
    }

    get getMainRole() {
        return this.mainRole;
    }

    get isHococoStaff() {
        return this.hococoStaff;
    }

    get role(): UserRoleData {
        return this.roleData;
    }

    set role(roleData: UserRoleData) {
        this.roleData = roleData;
    }

    public setProfileImage(image: string) {
        this.profileImage = image;
    }

    get getProfileImageThumbnail() {
        return this.profileImageThumbnail;
    }

    public setProfileImageThumbnail(image: string) {
        this.profileImageThumbnail = image;
    }

    public setRoleId(roleId: number) {
        this.roleId = roleId;
    }

    public setResidentPropertyData(data: ResidentPropertyData): void {
        this.residentPropertyData = data;
    }

    public setFeatures(features: string[]) {
        this.features = features;
    }

    public hasAccessTo(feature: string) {
        return this.features.includes(feature);
    }

    public setPersonalData(data: any) {
        this.firstName   = data.firstName;
        this.familyName  = data.familyName;
        this.phoneNumber = data.phoneNumber;
    }

    public setMainRole(role: string) {
        this.mainRole = role;
    }

    public setStaffTitle(title: string): void {
        this.staffTitle = title;
    }
}
