/* eslint-disable */

import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
// @ts-ignore
import en from '@vee-validate/i18n/dist/locale/en.json';
// @ts-ignore
import da from '@vee-validate/i18n/dist/locale/da.json';

configure({
    generateMessage: localize({
      en,
      da,
    }),
  });