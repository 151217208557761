/* eslint-disable */

import moment from 'moment';
import axios from 'axios';
import { Vue } from 'vue-class-component';
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators';

import router from '@/router';
import store from '@/store';
import Mutations from '@/store/mutations';
import Endpoints from '@/api/endpoints';
import { OAuth } from '@/constants/OAuthConstants';
import Actions from '@/store/actions';
import AuthData from '@/models/AuthData';
import PersonData from '@/models/PersonData';

import { deleteState, getState } from '@/plugins/storage';

@Module({ namespaced: true })
export default class User extends VuexModule {

    public authData: AuthData | null = null;
    public personData: PersonData | null = null;
    public resetPasswordEmail: string = '';
    public resettingPassword: boolean = false;
    public resetPasswordEmailSent: boolean = false;

    get getAccessToken() {
        return this.authData.accessToken;
    }

    get getUser() {
        return this.personData;
    }

    get getUserId() {
        return this.personData?.id;
    }

    @Action
    public async CHECK_AUTHENTICATION(): Promise<boolean> {
        return new Promise<boolean>(async (resolve: any, reject: any) => {
            try {
                if (this.authData && this.personData) {
                    resolve(true);
                    return;
                }

                const cached: any = await getState();

                if (cached?.User?.authData) {
                    store.commit(Mutations.UPDATE_AUTH_DATA, cached.User.authData)
                    await store.dispatch(Actions.FETCH_IDENTITIES, { fromLoginInterface: false });
                    resolve(true);
                } else {
                    resolve(false);
                }

            } catch (error) {
                console.log(error)
                resolve(false)
            }
        })
    }

    @Mutation
    public RESET_USER_STORE_STATE(): void {
        this.authData = null;
        this.personData = null;
    }

    @Action
    public async LOG_IN(payload: { email: string, password: string, redirectTo?: string }) {
        return new Promise((resolve, reject) => {
            const requestPayload = new FormData();

            requestPayload.append('grant_type', 'password');
            requestPayload.append('client_id', OAuth.CLIENT_ID);
            requestPayload.append('client_secret', OAuth.CLIENT_SECRET);
            requestPayload.append('username', payload.email);
            requestPayload.append('password', payload.password);
            requestPayload.append('scope', '');

            axios.post(Endpoints.Users.AUTH_ENDPOINT, requestPayload).then((response) => {
                const authResponse = new AuthData(
                    response.data.access_token,
                    response.data.refresh_token,
                    response.data.token_type,
                    response.data.expires_in,
                );

                store.commit(Mutations.UPDATE_AUTH_DATA, authResponse);

                store.dispatch(Actions.FETCH_IDENTITIES, {
                    fromLoginInterface: true,
                }).then(() => {
                    resolve(true);
                })
            }).catch((error) => {
                console.log(error)
                reject(error);
            })
        });
    }

    @Mutation
    public UPDATE_AUTH_DATA(authData: AuthData): void {
        this.authData = authData;
        axios.defaults.headers.common.Authorization = 'Bearer ' + this.authData.accessToken;
    }

    @Mutation
    public UPDATE_PERSON_DATA(personData: PersonData): void {
        this.personData = personData;
    }


    @Action
    public LOG_OUT() {
        deleteState();
        store.commit(Mutations.RESET_USER_STORE_STATE);
        store.commit(Mutations.RESET_IDENTITIES_STORE_STATE)
        router.push('/login');
    }

    @Action
    public UPDATE_USER(data: PersonData) {
        return new Promise<any>(async (resolve: any, reject: any) => {
            axios.patch(Endpoints.Users.USER_ENDPOINT, {
                user: {
                    firstName: data.firstName,
                    familyName: data.familyName,
                    zip: data.zipCode,
                    city: data.city,
                    street: data.address,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                },
            }).then(() => {
                resolve(true)
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }

    @Mutation
    public PASSWORD_RESET_SUCCESS(): void {
        this.resettingPassword = false;
        this.resetPasswordEmailSent = true;
    }

    @Mutation
    public PASSWORD_RESET_FAILURE(): void {
        this.resetPasswordEmailSent = false;
    }

    @Mutation
    public RESET_RESET_PASSWORD(): void {
        this.resettingPassword = false;
        this.resetPasswordEmailSent = false;
        this.resetPasswordEmail = '';
    }

    @Mutation
    public SET_CURRENT_PROFILE_IMAGE(imageData: string, thumbnailData: string = null): void {
        this.personData.profileImage = imageData;
        this.personData.profileImageThumbnail = (thumbnailData) ? thumbnailData : imageData;
    }

    @Mutation
    public RESET_CURRENT_PROFILE_IMAGE(): void {
        this.personData.profileImage = '';
        this.personData.profileImageThumbnail = '';
    }

    @Action
    public RESET_PASSWORD(data: any) {

        const requestData = typeof data === 'undefined' ? { email: User.state.resetPasswordEmail } : data;

        return new Promise((resolve, reject) => {
            axios.post(Endpoints.Users.RESET_PASSWORD, requestData)
                .then((response) => {
                    if (typeof data === 'undefined') {
                        if (response.data && response.data.status) {
                            if (response.data.status === true) {
                                store.commit(Mutations.PASSWORD_RESET_SUCCESS);
                                resolve(true);
                            } else {
                                store.commit(Mutations.PASSWORD_RESET_FAILURE);
                                reject(true);
                            }
                        }

                        store.commit(Mutations.PASSWORD_RESET_FAILURE);
                        reject(true);
                    } else {
                        resolve(true);
                    }
                })
                .catch((error) => {
                    store.commit(Mutations.PASSWORD_RESET_FAILURE);
                    console.log(error);
                    reject(error);
                });
        });
    }

    @Action
    public SAVE_PROFILE_IMAGE(imageData: string) {
        return new Promise((resolve, reject) => {
            const requestData = {
                imageData,
            };

            axios.post(Endpoints.Users.PROFILE_IMAGE, requestData)
                .then((response) => {
                    store.commit(Mutations.SET_CURRENT_PROFILE_IMAGE, imageData);

                    resolve(true);
                })
                .catch((error) => {
                    console.log(error);

                    reject(true);
                });
        });
    }

    @Action
    public DELETE_PROFILE_IMAGE() {
        return new Promise((resolve, reject) => {
            axios.delete(Endpoints.Users.PROFILE_IMAGE)
                .then(() => {
                    store.commit(Mutations.RESET_CURRENT_PROFILE_IMAGE);

                    resolve(true);
                })
                .catch((error) => {
                    console.log(error);

                    reject(true);
                });
        });
    }
}

