
import { computed } from 'vue';

export default {
    props: {
		src: {
			type: String
		}
	},
	setup(props) {
		const imageSrc = computed(() => props.src ? props.src : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8/x8AAuMB8DtXNJsAAAAASUVORK5CYII=');

		return {
			imageSrc,
		}
	} 
}
