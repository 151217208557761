import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex';

import { store as storage } from '@/plugins/storage';
import Actions from '@/store/actions';
import useDateUtils from './useDateUtils';

const DEFAULT_LANGUAGE_KEY = 'en';
const CACHED_LANGUAGE_KEY = 'langKey';

const selectedLocaleKey = ref(null);

export default function useI18n () {
    const store = useStore();
    const { setLocale } = useDateUtils();

    const availableLanguages = computed(() => store.getters['Localization/getAllLanguages'])

    const currentLocalizationKey = computed(() => selectedLocaleKey.value);

    function setLocalization(key){
        if (availableLanguages.value.map(lang => lang.key).includes(key)){
            selectedLocaleKey.value = key;
            store.dispatch(Actions.UPDATE_USER_LANGUAGE, key)
            storage.setItem(CACHED_LANGUAGE_KEY, key);
            setLocale(key);
        } else {
            console.log("Localization key does not exist")
        }
    }

    async function checkLocalization() {
        if (!selectedLocaleKey.value){
            //checks cache
            const cachedValue = await storage.getItem(CACHED_LANGUAGE_KEY);
            selectedLocaleKey.value = cachedValue ?? DEFAULT_LANGUAGE_KEY;
            store.dispatch(Actions.UPDATE_USER_LANGUAGE, selectedLocaleKey.value);
            setLocale(selectedLocaleKey.value); 
        }
    }

	onMounted(async () => {
		await checkLocalization();
	})

	return {
		availableLanguages,
        currentLocalizationKey,
        setLocalization,
	}
}