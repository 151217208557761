
export default function useTextUtils () {

	function filterText(text: string): string {
		let filtered = text.replace(/<iframe[^>]+>.*?<\/iframe>/gm, '');
		filtered = filtered.replace(/<img.*?src="(.*?)"[^>]+>/gm, '');
    
		return filtered;
	}
    
	return {
		filterText,
	}
}
