export default class FacilityAvailablePeriodData {
	public static fromResponseData(responseData: any) {
		return new FacilityAvailablePeriodData(
			responseData.start_time,
			responseData.end_time,
		);
	}

    public startTime: string;
    public endTime: string;

    constructor(
    	startTime: string,
    	endTime: string,
    ) {
    	this.startTime = startTime;
    	this.endTime = endTime;
    }
}
