
import PersonData from '@/models/PersonData';
import { computed } from '@vue/reactivity';
import Separator from '@/components/common/Separator.vue';

export default {
	components: {
		Separator,
	},
	props: {
		userInfo: {
			type: PersonData,
			required: true,
		},
	},
	setup(props) {
		const profileImage = computed(() => {
			const userImage = props.userInfo.profileImage ? props.userInfo.profileImage: null;
			
			return props.userInfo.miniatureImage ? props.userInfo.miniatureImage : userImage;
		});

		return {
			profileImage,
		};
	},
};
