import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/en';
import 'dayjs/locale/da';
import 'dayjs/locale/es';

dayjs.locale('en');
dayjs.extend(localizedFormat)
dayjs.extend(utc);


export default function useDateUtils () {

	function getLocalDate(date: string): string | null {
		const dateTimeObject = dayjs(date);
		return dateTimeObject.isValid() ? dateTimeObject.local().format('L') : null;
	}

	function getLocalDateTime(date: string): string | null {
		const dateTimeObject = dayjs(date).utc(true);
		return dateTimeObject.isValid() ? dateTimeObject.local().format('L - LT') : null;
	}

	function getLocalShortDate(date: string): string | null {
		const dateTimeObject = dayjs(date).utc(true);
		return dateTimeObject.isValid() ? dateTimeObject.local().format('ll') : null;
	}

	function getLocalTime(date: string): string | null {
		const dateTimeObject = dayjs(date).utc(true);
		return dateTimeObject.isValid() ? dateTimeObject.local().format('LT') : null;
	}

	function setLocale(key){
		dayjs.locale(key);
	}
    
	return {
		getLocalDate,
		setLocale,
		getLocalDateTime,
		getLocalShortDate,
		getLocalTime,
	}
}