import store from '@/store';
import axios from 'axios';
import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators';
import Mutations from '@/store/mutations';
import Endpoints from '@/api/endpoints';
import ErrorResponse from '@/models/ErrorResponse';
import DashboardNotification from '@/models/notifications/DashboardNotification';
import NotificationType from '@/models/notifications/NotificationType';
import { TenantNotificationResponse } from '@/interfaces/NotificationInterfaces';
import { cloneDeep } from 'lodash';
@Module({namespaced: true})
export default class TenantNotifications extends VuexModule {

    /** Dashboards notifications */
    public dashboardNotifications: DashboardNotification[] = [];
    public dashboardPage = 1;
    public dashboardLastPage = 0;
	public isLoadingNotifications = false;

    /** Detailed notifications */
    public activeNotification: DashboardNotification = null;

    @Mutation
    public RESET_DASHBOARD_STORE_STATE() {
    	this.dashboardNotifications  = [];
    	this.dashboardPage           = 1;
    	this.dashboardLastPage       = 0;
    	this.activeNotification = null;
    }

    get getDashboardNotifications(): DashboardNotification[] {
    	return this.dashboardNotifications;
    }

    get getDashboardPage(): number {
    	return this.dashboardPage;
    }

    get getDashboardLastPage(): number {
    	return this.dashboardLastPage;
    }

    get isLastDashboardPage(): boolean {
    	return this.dashboardLastPage === this.dashboardPage;
    }

    get getActiveNotification(): DashboardNotification {
    	return this.activeNotification;
    }

    get getIsLoadingNotifications(): boolean {
    	return this.isLoadingNotifications;
    }


    @Action
    public FETCH_DASHBOARD_NOTIFICATIONS(requestObj: any) {
    	store.commit(Mutations.SET_IS_LOADING_NOTIFICATIONS, true);
    	return axios.get(Endpoints.Notifications.DASHBOARD_NOTIFICATIONS(), {
    		params: {
    			page: requestObj.page,
    			type: requestObj.type,
    		},
    	})
    		.then((response) => {
    			store.commit(Mutations.SET_IS_LOADING_NOTIFICATIONS, false);
    			if (requestObj.hardReload) {
    				store.commit(Mutations.RESET_DASHBOARD_NOTIFICATIONS);
    			}

    			if (response.data.data.length) {

    				store.commit(Mutations.ADD_DASHBOARD_NOTIFICATIONS, response.data.data);
    				store.commit(Mutations.SET_DASHBOARD_PAGE, requestObj.page + 1);
    			} else {
    				store.commit(Mutations.SET_LAST_DASHBOARD_PAGE);
    			}
    		})
    		.catch((error) => {
    			store.commit(Mutations.SET_IS_LOADING_NOTIFICATIONS, false);
    			if (error.response) {
    				const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
    				store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
    			} else {
    				console.log(error);
    			}
    		});
    }

	@Action
    public SET_NOTIFICATION_USER_LIKE(payload: {value: boolean, notificationId: number, userId: number}): void {
    	const dashboardNotificationIndex: number = this.dashboardNotifications.findIndex((n) => n.id === payload.notificationId)
    	const userId = payload.userId;

    	//Updates array if notification with notificationId is present in array
    	if(dashboardNotificationIndex !== -1) {
    		const dashboardNotificationArrayCopy: DashboardNotification[] = cloneDeep(this.getDashboardNotifications);
    		const dashboardNotificationCopy: DashboardNotification = dashboardNotificationArrayCopy[dashboardNotificationIndex];
    		payload.value ? dashboardNotificationCopy.addLike(userId) : dashboardNotificationCopy.removeLike(userId)
    		store.commit(Mutations.SET_DASHBOARD_NOTIFICATIONS, dashboardNotificationArrayCopy);
    	}

    	//Updates active notification if it is the selected notification
    	if(this.getActiveNotification && this.getActiveNotification.id === payload.notificationId) {
    		const activeNotificationCopy = cloneDeep(this.activeNotification);
    		payload.value ? activeNotificationCopy.addLike(userId) : activeNotificationCopy.removeLike(userId)
    		store.commit(Mutations.SET_ACTIVE_NOTIFICATION, activeNotificationCopy);
    	}
    }


    @Action
	public FETCH_TENANT_NOTIFICATION(notificationId: number) {
    	return axios.get(Endpoints.Notifications.SHOW_TENANT_NOTIFICATION(notificationId))
    	    .then((response) => {
				const notification = response.data.data;
				const dashboardNotification = new DashboardNotification(
					notification.id,
					notification.eventId,
					notification.title,
					notification.text,
					notification.requiresResponse,
					notification.seen,
					notification.hasMedia,
					notification.publishedAt,
					notification.createdAt,
					notification.updatedAt,
					notification.startTime,
					notification.endTime,
					notification.likesCount,
					notification.commentsCount,
					notification.likesRecipients,
					notification.attenders,
					notification.place,
					notification.attending,
				);
		
				dashboardNotification.setNotificationAuthor(notification.author);
				dashboardNotification.setNotificationType(new NotificationType(
					notification.type.id,
					notification.type.key,
					notification.type.description,
				));
		
    	        store.commit(Mutations.SET_ACTIVE_NOTIFICATION, dashboardNotification);
    	    })
    	    .catch((error) => {
    	        if (error.response) {
    	            const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
    	            store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
    	        } else {
    	            console.log(error);
    	        }
    	    });
	}

    // @Action
    // public FETCH_TENANT_UPCOMING_EVENTS() {
    //     return axios.get(Endpoints.Notifications.TENANT_UPCOMING_EVENTS)
    //         .then((response) => {
    //             store.commit(Mutations.SET_TENANT_UPCOMING_EVENTS, response.data.data);
    //         })
    //         .catch((error) => {
    //             if (error.response) {
    //                 const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
    //                 store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
    //             } else {
    //                 console.log(error);
    //             }
    //         });
    // }

	@Mutation
    public SET_IS_LOADING_NOTIFICATIONS(isLoading: boolean) {
    	this.isLoadingNotifications = isLoading;
    }

    @Mutation
	public SET_DASHBOARD_PAGE(page: number) {
    	this.dashboardPage = page;
	}

    @Mutation
    public SET_LAST_DASHBOARD_PAGE() {
    	this.dashboardLastPage = this.dashboardPage;
    }

    @Mutation
    public RESET_DASHBOARD_NOTIFICATIONS() {
    	this.dashboardLastPage = 0;
    	this.dashboardNotifications = [];
    }

    @Mutation
    public ADD_DASHBOARD_NOTIFICATIONS(notificationData: TenantNotificationResponse[]) {
    	for (const notification of notificationData) {
    		const dashboardNotification = new DashboardNotification(
    			notification.id,
    			notification.eventId,
    			notification.title,
    			notification.text,
    			notification.requiresResponse,
    			notification.seen,
    			notification.hasMedia,
    			notification.publishedAt,
    			notification.createdAt,
    			notification.updatedAt,
    			notification.startTime,
    			notification.endTime,
    			notification.likesCount,
    			notification.commentsCount,
    			notification.likesRecipients,
    			notification.attenders,
    			notification.place,
    			notification.attending,
    		);

    		dashboardNotification.setNotificationAuthor(notification.author);
    		dashboardNotification.setNotificationType(new NotificationType(
    			notification.type.id,
    			notification.type.key,
    			notification.type.description,
    		));

    		if (!this.dashboardNotifications.find((n) => n.id === dashboardNotification.id)) {
    			this.dashboardNotifications.push(dashboardNotification);
    		}
    	}
    }

	@Mutation
    public SET_DASHBOARD_NOTIFICATIONS(payload: DashboardNotification[]) {
    	this.dashboardNotifications = payload;
    }

	@Mutation
	public SET_ACTIVE_NOTIFICATION(notification: DashboardNotification) {
		this.activeNotification = notification;
	}

	@Mutation
	public RESET_ACTIVE_NOTIFICATION() {
		this.activeNotification = null;
	}


	// @Mutation
	// public ADD_DASHBOARD_NOTIFICATION_LIKE(data: any): void {
	// 	const dashboardNotification = this.dashboardNotifications.find((n) => n.id === data.notificationId);

	// 	if (dashboardNotification) {
	// 		if (!dashboardNotification.likesRecipients.find((recipient: any) => recipient === store.getters['User/getUserId'])) {
	// 			dashboardNotification.addLike(data.likesCount, store.getters['User/getUserId']);
	// 		}
	// 	}
	// }

	// @Mutation
	// public REMOVE_DASHBOARD_NOTIFICATION_LIKE(data: any): void {
	// 	const dashboardNotification = this.dashboardNotifications.find((n) => n.id === data.notificationId);

	// 	if (dashboardNotification) {
	// 		dashboardNotification.removeLike(data.likesCount, store.getters['User/getUserId']);
	// 	}
	// }
}
