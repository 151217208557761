import Vue from 'vue';
import FacilityBlockConfigData from '@/models/sharedFacilities/FacilityBlockConfigData';
import FacilityTypeData from '@/models/sharedFacilities/FacilityTypeData';
import FacilityAvailablePeriodData from '@/models/sharedFacilities/FacilityAvailablePeriodData';

export const bookingTypes = {
	DAILY_BOOKING: 'daily',
	HOURLY_BOOKING: 'hourly',
	BLOCK_BOOKING: 'block',
};
export default class FacilityData {
	public static fromResponseData(responseData: any) {
		return new FacilityData(
			responseData.id,
			responseData.project_id,
			responseData.available,
			FacilityAvailablePeriodData.fromResponseData(responseData.available_period),
			FacilityTypeData.fromResponseData(responseData.type),
			responseData.block_config ? FacilityBlockConfigData.fromResponseData(responseData.block_config) : null,
			responseData.name,
			responseData.price,
			responseData.description,
			responseData.terms_and_conditions,
			responseData.thumbnail,
			responseData.availability_mask,
			responseData.minimum_lease_time,
			responseData.maximum_lease_time,
			responseData.booking_option,
			responseData.created_at,
			responseData.updated_at,
			responseData.deleted_at,
			responseData.books_count,
		);
	}

    public id: number;
    public type: FacilityTypeData;
    public block: FacilityBlockConfigData;
    public projectId: number; 
    public available: boolean; 
    public availablePeriod: FacilityAvailablePeriodData; 
    public name: string;
    public price: string;
    public description: string;
    public termsAndConditions: string;
    public thumbnail: string;
    public availabilityMask: string;
    public minimumLeaseTime: number;
    public maximumLeaseTime: number;
    public bookingOption: string;
    public createdAt: string;
    public updatedAt: string;
    public deletedAt: string;
    public booksCount: number;

    constructor(
    	id: number,
    	projectId: number,
    	available: boolean,
    	availablePeriod: FacilityAvailablePeriodData,
    	type: FacilityTypeData,
    	block: FacilityBlockConfigData,
    	name: string,
    	price: string,
    	description: string,
    	termsAndConditions: string,
    	thumbnail: string,
    	availabilityMask: string,
    	minimumLeaseTime: number,
    	maximumLeaseTime: number,
    	bookingOption: string,
    	createdAt: string,
    	updatedAt: string,
    	deletedAt: string,
    	booksCount: number,
    ) {
    	this.id                 = id;
    	this.projectId          = projectId;
    	this.available          = available;
    	this.availablePeriod    = availablePeriod;
    	this.type               = type;
    	this.block              = block;
    	this.name               = name;
    	this.price              = price;
    	this.description        = description;
    	this.termsAndConditions = termsAndConditions;
    	this.thumbnail          = thumbnail;
    	this.availabilityMask   = availabilityMask;
    	this.minimumLeaseTime   = minimumLeaseTime;
    	this.maximumLeaseTime   = maximumLeaseTime;
    	this.bookingOption      = bookingOption;
    	this.createdAt          = createdAt;
    	this.updatedAt          = updatedAt;
    	this.deletedAt          = deletedAt;
    	this.booksCount         = booksCount;
    }

    // get getName(): string {
    //     return this.type.getName;
    // }

    // get unavailableWeekDays(): any {
    //     const maskDays = this.availabilityMask.split('');
    //     maskDays.shift();

    //     const unavailableDays: any = [];

    //     maskDays.forEach((value, index) => {
    //         if (value === '0') {
    //             unavailableDays.push(index + 2 === 8 ? 1 : index + 2);
    //         }
    //     });

    //     return unavailableDays;
    // }

    // get status(): boolean {
    //     return Boolean(Number(this.availabilityMask.charAt(0)));
    // }

    // get requiredTimeSelect(): boolean {
    //     return typesWithTimeSelection.includes(this.bookingOption);
    // }

    get bookingType(): string {
    	if (this.hasHourlyBookingOption) return bookingTypes.HOURLY_BOOKING
    	if (this.hasBlockBookingOption) return bookingTypes.BLOCK_BOOKING
    	if (this.hasDailyBookingOption) return bookingTypes.DAILY_BOOKING
    }

    get hasHourlyBookingOption(): boolean {
    	return this.bookingOption === bookingTypes.HOURLY_BOOKING;
    }

    get hasBlockBookingOption(): boolean {
    	return this.bookingOption === bookingTypes.BLOCK_BOOKING;
    }

    get hasDailyBookingOption(): boolean {
    	return this.bookingOption === bookingTypes.DAILY_BOOKING;
    }
}
