
import { PropType } from 'vue';

import PersonData from '@/models/PersonData';
import UserAvatar from '@/components/common/UserAvatar.vue';
import useDateUtils from '@/composables/useDateUtils';

export default {
	components: { UserAvatar },
	props: {
		author: {
			type: Object as PropType<PersonData>,
			required: true
		},
        createdDateTime: {
            type: String,
            required: true
        }
	},
    setup() {
        const { getLocalDate } = useDateUtils();
        return {
            getLocalDate,
        }
    }
}
