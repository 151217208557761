
import axios from 'axios';
import { reactive, onMounted } from 'vue';

import endpoints from '@/api/endpoints';

export default {
	props: {
		id: {
			type: Number,
			required: true
		},
		hasMedia: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const state = reactive({
			media: null
		}) 

		async function fetchMedia() {
			const response = await axios.post(endpoints.Notifications.USER_NOTIFICATION_MEDIA(props.id))
			state.media = response.data.data;
		}

		onMounted(() => {
			if(props.hasMedia){
				fetchMedia();
			}
		})

		return {
			state
		}
	}
}
