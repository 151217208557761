import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "shared-facility-details-card__price-container" }
const _hoisted_2 = { class: "shared-facility-details-card__price-label" }
const _hoisted_3 = { class: "shared-facility-details-card__price-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shared_facility_detail_images = _resolveComponent("shared-facility-detail-images")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_body_text_1 = _resolveComponent("body-text-1")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_heading_4 = _resolveComponent("heading-4")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_bookings_side_bar = _resolveComponent("bookings-side-bar")!
  const _component_with_right_side_bar = _resolveComponent("with-right-side-bar")!
  const _component_booking_modal = _resolveComponent("booking-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_with_right_side_bar, null, {
      default: _withCtx(() => [
        ($setup.facility)
          ? (_openBlock(), _createBlock(_component_base_card, { key: 0 }, {
              default: _withCtx(() => [
                ($setup.facility)
                  ? (_openBlock(), _createBlock(_component_shared_facility_detail_images, {
                      key: 0,
                      facilityId: $setup.facility.id
                    }, null, 8, ["facilityId"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_card_section, { class: "shared-facility-details-card__info" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_heading_3, { innerHTML: $setup.filteredName }, null, 8, ["innerHTML"]),
                    _createVNode(_component_body_text_1, { innerHTML: $setup.filteredDescription }, null, 8, ["innerHTML"]),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_subtitle_1, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('general.available-from', 'Available from') + ": "), 1),
                          _createElementVNode("span", null, _toDisplayString($setup.facility.availablePeriod.startTime), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_subtitle_1, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.available-to', 'Available to') + ": "), 1),
                          _createElementVNode("span", null, _toDisplayString($setup.facility.availablePeriod.endTime), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_heading_4, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('shared-facilities.booking-type', 'Booking type')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_body_text_1, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.bookingType), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_brand_button, { onClick: $setup.onBookFacility }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('general.book-now', 'Book now')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      sidebar: _withCtx(() => [
        _createVNode(_component_bookings_side_bar)
      ]),
      _: 1
    }),
    ($setup.bookingModal)
      ? (_openBlock(), _createBlock(_component_booking_modal, {
          key: 0,
          modelValue: $setup.bookingModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.bookingModal) = $event)),
          facility: $setup.facility
        }, null, 8, ["modelValue", "facility"]))
      : _createCommentVNode("", true)
  ], 64))
}