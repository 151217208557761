import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_screen = _resolveComponent("mobile-screen")!
  const _component_FullScreenLoader = _resolveComponent("FullScreenLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.isMobileScreen)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.currentLayoutComponent), { key: 0 }, _createSlots({ _: 2 }, [
          _renderList(Object.keys(_ctx.$slots), (slotName) => {
            return {
              name: slotName,
              fn: _withCtx((slotProps) => [
                _renderSlot(_ctx.$slots, slotName, _normalizeProps(_guardReactiveProps(slotProps)))
              ])
            }
          })
        ]), 1024))
      : (_openBlock(), _createBlock(_component_mobile_screen, { key: 1 })),
    (_ctx.loadingStatus)
      ? (_openBlock(), _createBlock(_component_FullScreenLoader, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}