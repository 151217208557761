import axios from 'axios';
import store from '@/store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import ErrorResponse from '@/models/ErrorResponse';
import endpoints from '@/api/endpoints';
import Mutations from '@/store/mutations';
import BookingData from '@/models/BookingData';
import FacilityPhotoData from '@/models/sharedFacilities/FacilityPhotoData';
import FacilityData from '@/models/sharedFacilities/FacilityData';
import FacilityTypeData from '@/models/sharedFacilities/FacilityTypeData';
import FacilityBlockConfigData from '@/models/sharedFacilities/FacilityBlockConfigData';
import FacilityAvailablePeriodData from '@/models/sharedFacilities/FacilityAvailablePeriodData';
import * as moment from 'moment';
import Actions from '../actions';

export interface AddFacilityBookingRequest {
    date: string;
    startTime: string;
    endTime: string;
    facilityId: number;
    hourlyBooking: boolean;
}

@Module({namespaced: true})
export default class SharedFacilities extends VuexModule {
	public userSharedFacilities: FacilityData[] = [];
	public userFacilitiesBookings: BookingData[] = [];
	public userSelectedBooking: BookingData = null;
	public userSelectedFacility: FacilityData = null;
	public sharedFacilityPhotos: FacilityPhotoData[] = [];

	// @Mutation
	// public RESET_SHARED_FACILITIES_STORE_STATE() {

	//     this.userSharedFacilities               = [];
	//     this.userFacilitiesBookings             = [];
	//     this.userSelectedBooking                = null;
	//     this.userSelectedFacility               = null;
	// }

	get getUserSharedFacilities(): FacilityData[] {
		return this.userSharedFacilities;
	}



	get getUserFacilitiesBookings(): BookingData[] {
		return this.userFacilitiesBookings;
	}

	get getUserSelectedBooking(): BookingData {
		return this.userSelectedBooking;
	}

	get getUserSelectedFacility(): FacilityData {
		return this.userSelectedFacility;
	}

	get getSharedFacilityPhotos(): FacilityPhotoData[] {
		return this.sharedFacilityPhotos;
	}


	// @Action
	// public CANCEL_RESERVATION(data: any) {
	//     axios.delete(Endpoints.Admin.SharedFacilities.CANCEL_RESERVATION(data.bookingId))
	//         .then((response: any) => {
	//             store.commit(Mutations.REMOVE_FROM_ACTIVE_BOOKINGS, data.bookingId);
	//             NotifyHelpers.notify('booking_has_been_canceled', 'positive');
	//             store.commit(Mutations.RESET_CONFIRM_POPUP_DATA);
	//         })
	//         .catch((error) => {
	//             if (error.response) {
	//                 const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
	//                 store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
	//             } else {
	//                 console.log(error);
	//             }
	//         });
	// }

	@Action
	public FETCH_USER_SHARED_FACILITIES() {
		axios.get(endpoints.Booking.USER_SHARED_FACILITIES)
			.then((response) => {
				store.commit(Mutations.SET_USER_SHARED_FACILITIES, response.data);
			})
			.catch((error) => {
				if (error.response) {
					const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
					store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
				} else {
					console.log(error);
				}
			});
	}

	@Action
	public FETCH_USER_SHARED_FACILITY(facilityId: number) {
		axios.get(endpoints.Booking.USER_SHARED_FACILITY(facilityId))
			.then((response) => {
				store.commit(Mutations.SET_USER_SHARED_FACILITY, response.data);
			})
			.catch((error) => {
				if (error.response) {
					const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
					store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
				} else {
					console.log(error);
				}
			});
	}

	@Mutation
	public SET_USER_SHARED_FACILITIES(sharedFacilities: any[]): void {
		const facilityArray: FacilityData[] = [];
		for (const facility of sharedFacilities) {
			const type = FacilityTypeData.fromResponseData(facility.type);

			let block = null;

			if (facility.block_config) {
				block = FacilityBlockConfigData.fromResponseData(facility.block_config);
			}

			facilityArray.push(new FacilityData(
				facility.id,
				facility.project_id,
				facility.available,
				FacilityAvailablePeriodData.fromResponseData(facility.available_period),
				type,
				block,
				facility.name,
				facility.price,
				facility.description,
				facility.terms_and_conditions,
				facility.thumbnail,
				facility.availability_mask,
				facility.minimum_lease_time,
				facility.maximum_lease_time,
				facility.booking_option,
				facility.created_at,
				facility.updated_at,
				facility.deleted_at,
				facility.books_count,
			));
		}
		this.userSharedFacilities = facilityArray;
	}

	@Mutation
	public SET_USER_SHARED_FACILITY(facility: any): void {
		if (facility) {
			const type = FacilityTypeData.fromResponseData(facility.type);

			let block = null;

			if (facility.block_config) {
				block = FacilityBlockConfigData.fromResponseData(facility.block_config);
			}

			this.userSelectedFacility = new FacilityData(
				facility.id,
				facility.project_id,
				facility.available,
				FacilityAvailablePeriodData.fromResponseData(facility.available_period),
				type,
				block,
				facility.name,
				facility.price,
				facility.description,
				facility.terms_and_conditions,
				facility.thumbnail,
				facility.availability_mask,
				facility.minimum_lease_time,
				facility.maximum_lease_time,
				facility.booking_option,
				facility.created_at,
				facility.updated_at,
				facility.deleted_at,
				facility.books_count,
			);
		} else {
			this.userSelectedFacility = null;
		}
	}

	@Mutation
	public RESET_SHARED_FACILITIES_PHOTOS(): void {
		this.sharedFacilityPhotos = [];
	}

	@Mutation
	public SET_SHARED_FACILITIES_PHOTOS(photos): void {
		for (const photo of photos.data) {
			this.sharedFacilityPhotos.push(new FacilityPhotoData(
				photo.id,
				photo.order,
				photo.storage_guid,
				photo.base64,
				photo.created_at,
				photo.updated_at,
				photo.deleted_at,
			));
		}
	}

	@Action
	public FETCH_SHARED_FACILITIES_PHOTOS(facilityId: number) {
		axios.get(endpoints.Booking.SHARED_FACILITY_PHOTOS(facilityId))
			.then((response) => {
				store.commit(Mutations.RESET_SHARED_FACILITIES_PHOTOS);
				store.commit(Mutations.SET_SHARED_FACILITIES_PHOTOS, response);
			})
			.catch((error) => {
				if (error.response) {
					const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
					store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
				} else {
					console.log(error);
				}
			});
	}

	@Action
	public FETCH_USER_FACILITIES_BOOKINGS() {
		axios.get(endpoints.Booking.USER_FACILITIES_BOOKINGS)
			.then((response) => {
				store.commit(Mutations.RESET_USER_FACILITIES_BOOKINGS);
				store.commit(Mutations.SET_USER_FACILITIES_BOOKINGS, response);
			})
			.catch((error) => {
				if (error.response) {
					const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
					store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
				} else {
					console.log(error);
				}
			});
	}

	// @Action
	// public FETCH_FACILITY_UNAVAILABILITY(data: any) {
	//     axios.get(Endpoints.Booking.FACILITY_UNAVAILABILITY(data.facilityId, data.date))
	//         .then((response) => {
	//             store.commit(Mutations.SET_SHARED_FACILITIES_UNAVAILABILITY, response.data);
	//         })
	//         .catch((error) => {
	//             store.commit(Mutations.SET_USER_BOOKING_REQUESTS_STATUS, false);

	//             if (error.response) {
	//                 const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
	//                 store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
	//             } else {
	//                 console.log(error);
	//             }
	//         });
	// }

	// @Action
	// public ADD_USER_BOOKING(data: AddFacilityBookingRequest) {
	//     axios.post(Endpoints.Booking.ADD_USER_BOOKING(data.facilityId), {
	//         date: data.date,
	//         startTime: data.startTime,
	//         endTime: data.endTime,
	//         facilityId: data.facilityId,
	//     })
	//         .then((response: any) => {
	//             const booking = new BookingData(
	//                 response.data.id,
	//                 response.data.thumbnail,
	//                 response.data.name,
	//                 response.data.facility_id,
	//                 response.data.user_id,
	//                 response.data.user,
	//                 response.data.start_time,
	//                 response.data.end_time,
	//                 response.data.created_at,
	//                 response.data.updated_at,
	//                 response.data.deleted_at,
	//             );
	//             store.commit(Mutations.SET_BOOKED_FACILITY_OVERLAY_MODE, 'view-congrats');
	//             store.commit(Mutations.SET_USER_SELECTED_BOOKING, booking);
	//             store.commit(Mutations.SET_BOOK_FACILITY_OVERLAY_VISIBILITY, false);
	//             store.commit(Mutations.SET_FACILITY_DETAILS_OVERLAY_VISIBILITY, false);
	//             store.commit(Mutations.SET_BOOKED_FACILITY_OVERLAY_VISIBILITY, true);
	//         })
	//         .catch((error) => {
	//             if (error.response) {
	//                 const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
	//                 store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
	//             } else {
	//                 console.log(error);
	//             }
	//         });
	// }

	@Action
	public SUBMIT_BOOKING(data: AddFacilityBookingRequest) {
	    return axios.post(endpoints.Booking.ADD_USER_BOOKING(data.facilityId), {
	        date: data.date,
	        startTime: data.startTime,
	        endTime: data.endTime,
	        facilityId: data.facilityId,
	    })
		.catch((error) => {
			if (error.response) {
				const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
				store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
			} else {
				console.log(error);
			}
		});
	}

	@Action
	public CANCEL_USER_BOOKING(bookingId: number) {
		return new Promise((resolve, reject) => {
			axios.delete(endpoints.Booking.CANCEL_USER_BOOKING(bookingId))
				.then(() => {
					store.dispatch(Actions.FETCH_USER_FACILITIES_BOOKINGS);
					resolve(true);
				})
				.catch((error) => {
					if (error.response) {
						const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
						store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
					} else {
						console.log(error);
					}
					reject(true);
				});
		});
	}

	@Mutation
	public SET_USER_FACILITIES_BOOKINGS(bookings): void {
		for (const booking of bookings.data) {
			this.userFacilitiesBookings.push(new BookingData(
				booking.id,
				booking.thumbnail,
				booking.name,
				booking.facility_id,
				booking.user_id,
				booking.user,
				booking.start_time,
				booking.end_time,
				booking.created_at,
				booking.updated_at,
				booking.deleted_at,
			));
		}
	}

	@Mutation
	public SET_USER_SELECTED_BOOKING(booking: BookingData | null): void {
		this.userSelectedBooking = booking;
	}

	@Mutation
	public SET_USER_SELECTED_FACILITY(facility): void {
		this.userSelectedFacility = facility;
	}

	@Mutation
	public RESET_USER_SHARED_FACILITIES(): void {
		this.userSharedFacilities = [];
	}

	@Mutation
	public RESET_USER_FACILITIES_BOOKINGS(): void {
		this.userFacilitiesBookings = [];
	}
}
