
import { useRouter } from 'vue-router';
import Actions from '@/store/actions';
import { useStore } from 'vuex';
import { ref } from '@vue/reactivity';
import { useQuasar } from 'quasar';
export default {
	props: {
		bookingId: {
			type: [Number, String],
			required: true,
		},
	},
	setup(props) {
		const router = useRouter();
		const store = useStore();
		const $q = useQuasar();
		const isCancelled = ref(false);

		function cancelBooking() {
			store.dispatch(Actions.CANCEL_USER_BOOKING, props.bookingId)
				.then(() => {
					isCancelled.value = true;
				})
				.catch(() => {
					$q.notify({
						message: 'Something went wrong! Please try again!!',
						type: 'warning',
					});
				});
		}

		return { isCancelled, cancelBooking };
	},
};
