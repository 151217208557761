import store from '@/store';
import PersonData from '@/models/PersonData';
import NotificationType from '@/models/notifications/NotificationType';

export default class DashboardNotification {
    public id: number;
    public eventId: number;
    public title: string;
    public text: string;
    public requiresResponse: boolean;
    public seen: boolean;
    public hasMedia: boolean;
    public publishedAt: string;
    public createdAt: string;
    public updatedAt: string;
    public startTime: string;
    public endTime: string;
    public likesCount = 0;
    public commentsCount = 0;
    public place = '';
    public attendingStatus = false;

    public type: NotificationType = null;
    public author: PersonData = null;
    public attenders: number[];
    public likesRecipients: number[];


    constructor(
    	id: number,
    	eventId: number,
    	title: string,
    	text: string,
    	requiresResponse: boolean,
    	seen: boolean,
    	hasMedia: boolean,
    	publishedAt: string,
    	createdAt: string,
    	updatedAt: string,
    	startTime: string,
    	endTime: string,
    	likesCount = 0,
    	commentsCount = 0,
    	likesRecipients: number[],
    	attenders: number[],
    	place = '',
    	attendingStatus = false,
    ) {
    	this.id               = id;
    	this.eventId          = eventId;
    	this.title            = title;
    	this.text             = text;
    	this.requiresResponse = requiresResponse;
    	this.seen             = seen;
    	this.hasMedia         = hasMedia;
    	this.publishedAt      = publishedAt;
    	this.createdAt        = createdAt;
    	this.updatedAt        = updatedAt;
    	this.startTime        = startTime;
    	this.endTime          = endTime;
    	this.likesCount       = likesCount;
    	this.commentsCount    = commentsCount;
    	this.likesRecipients  = likesRecipients;
    	this.attenders        = attenders;
    	this.place            = place;
    	this.attendingStatus  = attendingStatus;
    }

    get component(): string {
    	if (this.type.isEvent) {
    		return 'EventCard';
    	} else {
    		return 'BroadcastCard';
    	}
    }

    get haveLike(): boolean {
    	return typeof this.likesRecipients.find((recipient: any) => recipient === store.getters['User/getUserId']) !== 'undefined';
    }

    get attending(): boolean {
    	return typeof this.attenders.find((attender: any) => attender === store.getters['User/getUserId']) !== 'undefined';
    }

    get likeCount(): number {
    	return this.likesRecipients.length;
    }

    get sortingDate(): string  {
    	return this.publishedAt ?? this.createdAt;
    }


    public setNotificationAuthor(author: any): void {
    	this.author = new PersonData(
    		author.id,
    		author.firstName,
    		author.lastName,
    	);
    	this.author.setMainRole(author.mainRole);
    	this.author.setStaffTitle(author.title);
    }

    public setNotificationType(type: NotificationType): void {
    	this.type = type;
    }

    public changeAttendeesList(status: boolean): void {
    	const userId = store.getters['User/getUserId'];

    	if (status) {
    		this.attenders.push(store.getters['User/getUserId']);
    		this.attendingStatus = true;
    	} else {
    		this.attenders = this.attenders.filter((a: any) => a !== userId);
    		this.attendingStatus = false;
    	}
    }

    public addLike(userId: number): void {
    	this.likesRecipients.push(userId);
    }

    public removeLike(userId: number): void {
    	this.likesRecipients = this.likesRecipients.filter((recipient: any) => recipient !== userId);
    }

    public read() {
    	this.seen = true;
    }
}
