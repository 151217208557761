
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

import Actions from '@/store/actions';
import MenuItem from '../components/MenuItem.vue';
import useI18n from '@/composables/useI18n';

export default {
	components: {
		MenuItem,
	},
	setup() {
		const showProfileMenu = ref(false);
		const showLanguageMenu = ref(false);

		const { availableLanguages, currentLocalizationKey, setLocalization } = useI18n();

		const store = useStore();

		const userData = computed(() => {
			return store.state.User.personData;
		});

		const profileImage = computed(() => {
			if(!userData.value) return null;
			
			const userImage = userData.value.profileImage
				? userData.value.profileImage
				: null;

			return userData.value.miniatureImage
				? userData.value.miniatureImage
				: userImage;
		});

		function logout() {
			store.dispatch(Actions.LOG_OUT);
		}

		function onLanguageSelected(langKey) {
			showLanguageMenu.value = false;
			setLocalization(langKey);
		}

		return {
			logout,
			profileImage,
			showProfileMenu,
			showLanguageMenu,
			currentLocalizationKey,
			availableLanguages,
			onLanguageSelected
		};
	},
};
