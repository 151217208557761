

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


export default {
	components: {
		FontAwesomeIcon
	},
	props: {
		bold: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			default: '',
			required: true,
		}
	}
}
