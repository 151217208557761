import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c1da777"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "hococo-auth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_2 = _resolveComponent("heading-2")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_card, { class: "auth-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_heading_2, { class: "text-center q-mb-sm q-mt-none" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ]),
          _: 3
        }),
        _createVNode(_component_subtitle_1, { class: "auth-card__subtitle text-center" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
          ]),
          _: 3
        }),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}