import { ref, unref, onMounted, onUnmounted } from 'vue'
export default function useIntersectionObserver (elRef) {
	let observer = null
	const isIntersecting = ref(false)
	onMounted(() => {
		const $el = unref(elRef)
		if (!($el instanceof HTMLElement))
			throw new Error('useIntersectionObserver: elRef is not an HTMLElement')

		const intersectionCallback = entries => {
			entries.forEach(entry => {
				isIntersecting.value = entry.isIntersecting
			})
		}
		observer = new IntersectionObserver(intersectionCallback)
		observer.observe($el)
	})

	onUnmounted(() => {
        observer?.disconnect()
	})

	return {
		isIntersecting,
		observer,
	}
}