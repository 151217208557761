import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b413058"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shared-facility-card__actions" }
const _hoisted_2 = { class: "shared-facility-card__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shared_facility_card_image = _resolveComponent("shared-facility-card-image")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_body_text_1 = _resolveComponent("body-text-1")!
  const _component_brand_button = _resolveComponent("brand-button")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_shared_facility_card_image, {
        image: $props.facility.thumbnail
      }, null, 8, ["image"]),
      _createVNode(_component_q_card_section, { class: "shared-facility-card__info" }, {
        default: _withCtx(() => [
          _createVNode(_component_heading_3, { innerHTML: $setup.filteredName }, null, 8, ["innerHTML"]),
          _createVNode(_component_body_text_1, {
            class: "shared-facility-card__text",
            innerHTML: $setup.filteredDescription
          }, null, 8, ["innerHTML"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_brand_button, { onClick: $setup.redirectToDetails }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('shared-facility.view-facility', 'View facility')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_subtitle_1, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString($setup.bookingType), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}