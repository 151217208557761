import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createBlock(_component_q_img, {
    "no-default-spinner": false,
    src: $setup.state.media ? $setup.state.media.base64 : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8/x8AAuMB8DtXNJsAAAAASUVORK5CYII=',
    width: "100%",
    "native-context-menu": "",
    class: "event-card-image"
  }, null, 8, ["src"]))
}