
export default {
	props: {
		minHeight: {
			type: String,
		},
		svgWidth: {
			type: String,
		},
		svgHeight: {
			type: String,
		},
		svgColor: {
			type: String,
		},
	},
};
