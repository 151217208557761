/* eslint-disable */

const currentVersion = 'v2';
import { Connection } from '@/configuration/Connection';
const apiUrl = Connection.getApiUrl();


export default {
    Users: {
        USER_ENDPOINT: `${apiUrl}/api/user`,
        RESET_PASSWORD: `${apiUrl}/api/user/reset-password`,
        CHANGE_PASSWORD: `${apiUrl}/api/user/change-password`,
        DASHBOARD_ITEMS_PER_PAGE_COUNT: `${apiUrl}/api/user/dashboard/page-count`,
        USER_FEEDBACK: (eventId: number) => `${apiUrl}/api/user/feedback/${eventId}`,
        FEEDBACK_EVENTS: `${apiUrl}/api/feedback/events`,
        PROFILE_IMAGE: `${apiUrl}/api/user/profile-image`,
        MINIATURE: (userId: number) => `${apiUrl}/api/user/${userId}/profile-image/miniature`,
        USER_PROFILE_IMAGE: (userId: number, type: string) => `${apiUrl}/api/user/profile-image/${userId}/${type}`,
        AUTH_ENDPOINT: `${apiUrl}/oauth/token`,
    },
    Identities: {
        IDENTITIES: `${apiUrl}/api/user/identities`,
        IDENTITIES_NOTIFICATIONS: `${apiUrl}/api/user/identities/notifications`,
    },
    Notifications: {
        NOTIFICATIONS_LIST: `${apiUrl}/api/user/notifications`,
        NOTIFICATIONS_UNREAD_COUNT: `${apiUrl}/api/${currentVersion}/user/notifications/unread`,
        DASHBOARD_NOTIFICATIONS: () => `${apiUrl}/api/${currentVersion}/user/notifications`,
        SHOW_TENANT_NOTIFICATION: (notificationId: number) => `${apiUrl}/api/${currentVersion}/user/notifications/show/${notificationId}`,
        TENANT_UPCOMING_EVENTS: `${apiUrl}/api/${currentVersion}/user/events/upcoming`,
        USER_NOTIFICATION_MEDIA: (notificationId: number) => `${apiUrl}/api/user/notifications/${notificationId}/media`,
        USER_ADD_LIKE: `${apiUrl}/api/user/like`,
    },
    Booking: {
        USER_FACILITIES_BOOKINGS: `${apiUrl}/api/booking`,
        USER_FACILITIES_BOOKING: (bookingId: number) => `${apiUrl}/api/booking/${bookingId}`,
        USER_SHARED_FACILITIES: `${apiUrl}/api/booking/facilities`,
        USER_SHARED_FACILITY: (facilityId: number) => `${apiUrl}/api/booking/facilities/${facilityId}`,
        CANCEL_USER_BOOKING: (bookingId: number) => `${apiUrl}/api/booking/${bookingId}`,
        ADD_USER_BOOKING: (facilityId: number) => `${apiUrl}/api/booking/facilities/${facilityId}`,
        SHARED_FACILITY_PHOTOS: (facilityId: number) => `${apiUrl}/api/booking/facilities/${facilityId}/photos`,
        FACILITY_UNAVAILABILITY: (facilityId: number, date: string = null) => {
            return (date === null)
                ? `${apiUrl}/api/booking/facilities/${facilityId}/bookings`
                : `${apiUrl}/api/booking/facilities/${facilityId}/bookings?date=${date}`;
        },
    },
    Localization: {
        ALL_LANGUAGES: `${apiUrl}/api/all-languages`,
        LOCALIZATION_DATA: (language: string) => `${apiUrl}/api/language/${language}/texts`,
    }
};