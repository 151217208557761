/* eslint-disable */
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ErrorResponse from '@/models/ErrorResponse';


@Module({namespaced: true})
export default class Viewport extends VuexModule {
   
    // Error handling
    public errorMessages: ErrorResponse[] = [];

    get currentErrorMessage() {
        if (this.errorMessages.length === 0) {
            return null;
        }

        return this.errorMessages[0].message;
    }

    @Mutation
    public ADD_ERROR_RESPONSE(responseData: ErrorResponse) {
        this.errorMessages.push(responseData);
    }

    @Mutation
    public DISMISS_CURRENT_ERROR_MESSAGE() {
        this.errorMessages.shift();
    }
}
