import store from '@/store';
import axios from 'axios';
import router from '@/router';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import Mutations from '@/store/mutations';
import Actions from '@/store/actions';
import IdentityData from '@/models/IdentityData';
import ErrorResponse from '@/models/ErrorResponse';
import {
	IdentitiesResponse,
	FetchIdentitiesParams,
} from '@/interfaces/IdentityInterfaces';

import PersonData from '@/models/PersonData';
import Endpoints from '@/api/endpoints';

@Module({namespaced: true})
export default class Identities extends VuexModule {
    public identities: IdentityData[] = [];
    public selectedIdentity: IdentityData = null;

    @Mutation
    public RESET_IDENTITIES_STORE_STATE() {
    	this.identities       = [];
    	this.selectedIdentity = null;
    }

    get getSelectedIdentity(): IdentityData {
    	return this.selectedIdentity;
    }

    get getIdentities(): IdentityData[]  {
    	return this.identities;
    }

    @Action
    public FETCH_IDENTITIES(params: FetchIdentitiesParams) {
    	return axios.get(Endpoints.Identities.IDENTITIES)
    		.then((response) => {
    			store.commit(Mutations.SET_IDENTITIES, response.data);
    			store.dispatch(Actions.SELECT_IDENTITY, 
    				new IdentityData(
    					response.data[0].parent,
    					response.data[0].context,
    					response.data[0].logo,
    					response.data[0].name,
    					response.data[0].role,
    					response.data[0].userType,
    				),
    			);
    		})
    		.catch((error) => {
    			// if (error.response) {
    			//     const errorResponse = new ErrorResponse(error.response.status, error.response.data.message);
    			//     store.commit(Mutations.ADD_ERROR_RESPONSE, errorResponse);
    			// } else {
    			//     console.log(error);
    			// }
    		});
    }

    @Action
    public SELECT_IDENTITY(data: IdentityData) {
    	return store.dispatch(Actions.SET_IDENTITY, data)
    		.then(() => {
    			axios.defaults.headers.common['Hococo-Context']   = String(data.context);
    			axios.defaults.headers.common['Hococo-User-Type'] = data.userType;

    			return;

    		}).then(() => {
    			return axios.get(Endpoints.Users.USER_ENDPOINT);
    		})
    		.then((response) => {
                
    			const user = new PersonData(
    				response.data.id,
    				response.data.person.first_name,
    				response.data.person.family_name,
    				response.data.person.phone_number,
    				response.data.address ? response.data.address.street : '',
    				response.data.address ? response.data.address.zip : '',
    				response.data.address ? response.data.address.city : '',
    				response.data.email,
    				response.data.property_id,
    				response.data.corporation_id,
    				response.data.isTenant,
    				response.data.mainRole,
    				response.data.hococoStaff,
    			);

    			user.setProfileImage(response.data.profileImage);
    			user.setProfileImageThumbnail(response.data.profileImageThumbnail);

    			user.setResidentPropertyData(response.data.propertyData);

    			store.commit(Mutations.UPDATE_PERSON_DATA, user);

    		}).catch((error) => {
    			console.log(error)
    		});
    }


    @Action
    public SET_IDENTITY(identity: IdentityData) {
    	store.commit(Mutations.SET_USER_IDENTITY, identity);
    	return Promise.resolve(this.selectedIdentity);
    }

    @Mutation
    public SET_USER_IDENTITY(identity: IdentityData) {
    	this.selectedIdentity = identity;
    }

    @Mutation
    public SET_IDENTITIES(identities: IdentitiesResponse[]) {
    	this.identities = [];

    	for (const identity of identities) {
    		this.identities.push(new IdentityData(
    			identity.parent,
    			identity.context,
    			identity.logo,
    			identity.name,
    			identity.role,
    			identity.userType,
    		));
    	}
    }
}
