import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ced81274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "facility-booked-card__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_booking_item_image = _resolveComponent("booking-item-image")!
  const _component_heading_3 = _resolveComponent("heading-3")!
  const _component_subtitle_1 = _resolveComponent("subtitle-1")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { class: "facility-booked-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_booking_item_image, {
        background: $props.booking.background,
        date: $props.booking.startTime
      }, null, 8, ["background", "date"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_heading_3, { class: "facility-booked-card__text__facility text-capitalize" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.booking.facilityName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_subtitle_1, { class: "booking-item__info__text__usage-time" }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString($setup.getLocalTime($props.booking.startTime)) + " - " + _toDisplayString($setup.getLocalTime($props.booking.endTime)), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}