
import { ref, onMounted, Ref } from 'vue';
import axios from 'axios';
import endpoints from '@/api/endpoints';

export default {
	props: {
		facilityId: {
			type: Number,
			required: true
		}
	},
    setup(props) {
        const images: Ref<string[]> = ref([]); 
        const slide: Ref<number> = ref(1);

		async function fetchMedia() {
			const response = await axios.get(endpoints.Booking.SHARED_FACILITY_PHOTOS(props.facilityId))
            if(response.data){
                images.value = response.data.map((image => {
                    return image.base64;
                }))
                console.log(images.value.length)
            }

            //Sort by order
			//state.media = response.data.data;
		}

		onMounted(() => {
            fetchMedia();	
		})

		return {
			images,
            slide,
		}
    }
    
}
