
import NotificationHeader from './NotificationHeader.vue';
import NotificationImage from './NotificationImage.vue';
import BroadcastCardInfo from './BroadcastCardInfo.vue';
import useGlobalModal from '@/composables/useGlobalModal';
import Separator from '@/components/common/Separator.vue'
   
export default {
	components: {
		NotificationHeader,
		NotificationImage,
		BroadcastCardInfo,
		Separator,
	},
	props: {
		notification: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		const { navigateToModal } = useGlobalModal();
		function redirectToModal() {
			navigateToModal(props.notification.id, 'notification');
		}

		return {
			redirectToModal,
		}
	}
}
