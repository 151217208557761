
import StandardLayout from './layouts/StandardLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import MobileScreen from '@/layout/layouts/MobileAppLayout.vue';
import { useQuasar } from 'quasar';
import router from '@/router';
import { mapGetters } from 'vuex';
import { computed } from 'vue';

import FullScreenLoader from '@/components/loaders/FullScreenLoader.vue';

const layoutComponents = {
	standard: StandardLayout,
	auth: AuthLayout,
};

export default {
	components: {
		FullScreenLoader,
		MobileScreen,
	},
	computed: {
		currentLayoutComponent() {
			const layout: string =
				String(router?.currentRoute?.value?.meta?.layout) || 'standard';
			return layoutComponents[layout];
		},
		...mapGetters({
			loadingStatus: 'Loading/getLoadingStatus',
		}),
	},
	setup() {
		const $q = useQuasar();
		const isMobileScreen = computed(() => $q.platform.is.mobile);

		return { isMobileScreen };
	},
};
