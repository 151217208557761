import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed1543a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-layout" }
const _hoisted_2 = { class: "auth-layout__header-container" }
const _hoisted_3 = { class: "auth-layout__left-side-bar-container" }
const _hoisted_4 = { class: "auth-layout__main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthLayoutHeader = _resolveComponent("AuthLayoutHeader")!
  const _component_ProfileInfoCard = _resolveComponent("ProfileInfoCard")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AuthLayoutHeader)
    ]),
    _createElementVNode("aside", _hoisted_3, [
      _createVNode(_component_ProfileInfoCard, {
        userInfo: $setup.user,
        class: "profile-info"
      }, null, 8, ["userInfo"]),
      _createVNode(_component_base_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_list, { class: "menu-list" }, {
            default: _withCtx(() => [
              _createVNode(_component_MenuItem, {
                to: "/",
                icon: "home"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.home', 'Home')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_MenuItem, {
                to: "/facilities",
                icon: "spa"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.facilities', 'Facilities')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_MenuItem, {
                to: "/facilities/bookings",
                icon: "calendar"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('general.my-bookings', 'My Bookings')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("main", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_base_modal, {
      modelValue: $setup.showModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showModal) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.modalComponent)))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}