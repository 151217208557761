import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
@Module({name: 'Loading', namespaced: true})
export default class Loading extends VuexModule {
  public loadingStatus = false;

  @Mutation
  public RESET_LOADING_STORE_STATE() {
  	this.loadingStatus              = false;
  }

  get getLoadingStatus(): boolean {
  	return this.loadingStatus;
  }

  @Mutation
  public SET_LOADING_STATUS(loadingStatus: boolean) {
  	this.loadingStatus = loadingStatus;
  }

}
