import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_feed = _resolveComponent("dashboard-feed")!
  const _component_BookingsSideBar = _resolveComponent("BookingsSideBar")!
  const _component_with_right_side_bar = _resolveComponent("with-right-side-bar")!

  return (_openBlock(), _createBlock(_component_with_right_side_bar, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_feed, {
        onOnLoadMore: $setup.onLoadMoreNotifications,
        notifications: $setup.notifications
      }, null, 8, ["onOnLoadMore", "notifications"])
    ]),
    sidebar: _withCtx(() => [
      _createVNode(_component_BookingsSideBar)
    ]),
    _: 1
  }))
}