export default class FacilityTypeData {
	public static fromResponseData(responseData: any) {
		return new FacilityTypeData(
			responseData.id,
			responseData.name,
			responseData.key,
			responseData.created_at,
			responseData.updated_at,
		);
	}

    public id: number;
    public name: string;
    public key: string;
    public createdAt: string;
    public updatedAt: string;

    constructor(
    	id: number,
    	name: string,
    	key: string,
    	createdAt: string,
    	updatedAt: string,
    ) {
    	this.id        = id;
    	this.name      = name;
    	this.key       = key;
    	this.createdAt = createdAt;
    	this.updatedAt = updatedAt;
    }

    get getName(): string {
    	return this.name;
    }
}
