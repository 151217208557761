import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dashboard-feed" }
const _hoisted_2 = {
  class: "dashboard-feed__observer",
  ref: "loadMoreRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortedNotifications, (notification) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(notification.component), {
        class: "dashboard-feed__item",
        key: notification.id,
        notification: notification
      }, null, 8, ["notification"]))
    }), 128)),
    _createElementVNode("div", _hoisted_2, null, 512)
  ]))
}