import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_q_carousel = _resolveComponent("q-carousel")!

  return ($setup.images.length < 1)
    ? (_openBlock(), _createBlock(_component_q_img, {
        key: 0,
        "no-default-spinner": false,
        src: $setup.images.length === 1 ? 'data:image/png;base64,' + $setup.images[0] : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8/x8AAuMB8DtXNJsAAAAASUVORK5CYII=',
        width: "100%",
        class: "image-container",
        "native-context-menu": ""
      }, null, 8, ["src"]))
    : (_openBlock(), _createBlock(_component_q_carousel, {
        key: 1,
        animated: "",
        modelValue: $setup.slide,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.slide) = $event)),
        arrows: "",
        navigation: "",
        infinite: "",
        class: "image-container"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.images, (item, index) => {
            return (_openBlock(), _createBlock(_component_q_carousel_slide, {
              key: index,
              name: index + 1,
              "img-src": 'data:image/png;base64,' + item
            }, null, 8, ["name", "img-src"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]))
}