
import { onMounted, computed, ComputedRef } from 'vue'
import { useStore } from 'vuex';

import WithRightSideBar from '@/layout/pageLayouts/WithRightSideBar.vue';
import Actions from '@/store/actions';
import BookingsSideBar from '@/views/Bookings/components/BookingsSideBar.vue';
import SharedFacilityCard from '@/views/SharedFacility/components/SharedFacilityCard.vue';
import FacilityData from '@/models/sharedFacilities/FacilityData';

export default {
	components: {
		WithRightSideBar,
		BookingsSideBar,
		SharedFacilityCard,
	},
	setup() {
		const store = useStore();

		const facilities: ComputedRef<FacilityData[]> = computed(() => {
			return store.getters['SharedFacilities/getUserSharedFacilities'];
		})

		onMounted(() => {
			store.dispatch(Actions.FETCH_USER_SHARED_FACILITIES);
		})

		return {
			facilities
		}
	}
}
